@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,body{
  background-color: #f5f5f5;
}
button,
html input[type=button],
input[type=reset],
input[type=submit] {
    -webkit-appearance: button;
    cursor: pointer;
}
button[disabled],
html input[disabled] {
    cursor: default
}
button::-moz-focus-inner,
input::-moz-focus-inner {
    padding: 0;
    border: 0;
    border-radius: 0;
}
button:active{
    /* padding: 0; */
    border: 0;
    border-radius: 0;
}
button:hover{
  /* padding: 0; */
  border: 0;
  border-radius: 0;
}
button:focus{
  /* padding: 0; */
  border: 0;
  border-radius: 0;
  outline: gainsboro 1px solid;
}

.panel-default {
  border-color: #ddd;
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
}
.form-group {
  margin-bottom: 15px;
}
.form-control{
  box-sizing: border-box;
  display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(102, 175, 233, .6);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.panel-body{
  padding: 15px;
}
.login-panel {
  width: 100%;
  max-width: 430px;
  margin: auto;
  position: relative;
}
.main {
  display: flex;
  justify-content: center;
  height: 100%;
}
html,
body {
  height: 100%;
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  font-weight: 400;
  vertical-align: baseline;
  /* background: #ffffff; */
  /* color: #444444; */
}
body {
  font-size: 15px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
  line-height: 1.5;
  font-weight: 400;
  margin: 0 0 20px 0;
  color: #212121;
}
h1 {
  font-size: 56px;
}
h2 {
  font-size: 30px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 20px;
}
p {
  line-height: 1.5;
  margin: 0 0 20px 0;
}
/*=======================================================================
[02] General
=========================================================================*/
a {
  text-decoration: none;
}
a:active,
a:hover,
a:focus {
  text-decoration: none;
}
a:active,
a:hover,
a:focus {
  outline: 0 none;
}
img {
  max-width: 100%;
  height: auto;
}
ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}
.clear:after {
  clear: both;
  content: "";
  display: block;
}
#wrapper {
  overflow: hidden;
  transition: all 0.5s ease-out;
}
.video-margin-top {
  margin-top: 12px;
}
.margin-bottom-none {
  margin-bottom: 0!important;
}
.margin-bottom-sidebar {
  margin-bottom: 30px;
}
.bg-common-style {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.section-divider {
  height: 1px;
  width: 100%;
  background: #bdbdbd;
}
.solid-underline {
  position: relative;
  padding-bottom: 8px;
  margin-bottom: 35px;
}
.solid-underline:after {
  position: absolute;
  content: "";
  height: 1px;
  width: 100%;
  background: #e3e3e3;
  left: 0;
  bottom: 0;
}
.bg-primary {
  background: #002147;
  color: #ffffff;
}
.bg-textPrimary {
  background: #ffffff;
  color: #002147;
}
.bg-primary-deep {
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-primary-deep:before {
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  background-color: rgba(0, 33, 71, 0.9);
}
.bg-primary-deep-fixed {
  background-attachment: fixed;
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.bg-primary-deep-fixed:before {
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  background-color: rgba(0, 33, 71, 0.9);
}
.overlay-primaryColor {
  position: relative;
}
.overlay-primaryColor:before {
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  background-color: rgba(0, 33, 71, 0.7);
}
.overlay-video {
  position: relative;
}
.overlay-video:before {
  content: "";
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  background-color: rgba(0, 33, 71, 0.88);
  height: 100%;
  width: 100%;
}
.title-bar-counter {
  position: relative;
}
.title-bar-counter:before {
  content: "";
  height: 2px;
  width: 70px;
  background: #fdc800;
  position: absolute;
  left: 0;
  margin: 0 auto;
  right: 0;
  bottom: 0;
  z-index: 3;
}
.title-bar-medium-left {
  position: relative;
}
.title-bar-medium-left:before {
  content: "";
  height: 3px;
  width: 40px;
  background: #fdc800;
  position: absolute;
  left: 0;
  bottom: -10px;
  z-index: 1;
  border-radius: 6px;
}
.title-bar-high {
  position: relative;
}
.title-bar-high:before {
  content: "";
  height: 3px;
  width: 40px;
  background: #fdc800;
  position: absolute;
  left: 0;
  bottom: -15px;
  z-index: 1;
  border-radius: 6px;
}
.title-bar-big-high {
  position: relative;
}
.title-bar-big-high:before {
  content: "";
  height: 3px;
  width: 40px;
  background: #fdc800;
  position: absolute;
  left: 0;
  bottom: -30px;
  z-index: 1;
  border-radius: 6px;
}
.title-bar-sm-high {
  position: relative;
}
.title-bar-sm-high:before {
  content: "";
  height: 3px;
  width: 40px;
  background: #fdc800;
  position: absolute;
  left: 0;
  bottom: -20px;
  z-index: 1;
  border-radius: 6px;
}
.title-bar-big-left-close {
  position: relative;
}
.title-bar-big-left-close:before {
  content: "";
  height: 4px;
  width: 45px;
  background: #fdc800;
  position: absolute;
  left: 0;
  bottom: -20px;
  z-index: 1;
}
.title-bar-footer {
  position: relative;
}
.title-bar-footer:before {
  content: "";
  height: 3px;
  width: 45px;
  background: #002147;
  position: absolute;
  left: 0;
  bottom: -20px;
  z-index: 1;
}
.title-bar-small-center {
  position: relative;
}
.title-bar-small-center:before {
  content: "";
  height: 2px;
  width: 40px;
  background: #002147;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -12px;
  margin: 0 auto;
  z-index: 1;
}
.title-bar-small-left {
  position: relative;
}
.title-bar-small-left:before {
  content: "";
  height: 3px;
  width: 45px;
  background: #002147;
  position: absolute;
  left: 0;
  bottom: -35px;
  z-index: 1;
}
.title-bar-sidebar {
  position: relative;
}
.title-bar-sidebar:before {
  content: "";
  height: 4px;
  width: 45px;
  background: #002147;
  position: absolute;
  left: 0;
  bottom: -15px;
  z-index: 1;
}
.title-bar-full-width {
  position: relative;
}
.title-bar-full-width:before {
  content: "";
  height: 2px;
  width: 100%;
  background: #002147;
  position: absolute;
  left: 0;
  bottom: -12px;
  z-index: 1;
}
.title-default-center {
  text-transform: capitalize;
  text-align: center;
  font-weight: 500;
  margin-bottom: 45px;
  color: #002147;
}
@media only screen and (max-width: 767px) {
  .title-default-center {
    font-size: 28px;
  }
}
@media only screen and (max-width: 480px) {
  .title-default-center {
    font-size: 26px;
  }
}
.title-default-left {
  text-transform: capitalize;
  text-align: left;
  font-weight: 500;
  margin-bottom: 45px;
  color: #002147;
}
@media only screen and (max-width: 767px) {
  .title-default-left {
    margin-bottom: 36px;
    font-size: 28px;
  }
}
@media only screen and (max-width: 480px) {
  .title-default-left {
    margin-bottom: 30px;
    font-size: 26px;
  }
}
.title-default-left a {
  color: #002147;
  transition: all 0.3s ease-out;
}
.title-default-left a:hover {
  color: #fdc800;
}
.title-default-left-bold {
  font-size: 30px;
  text-transform: capitalize;
  text-align: left;
  font-weight: 500;
  margin-bottom: 45px;
  color: #002147;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .title-default-left-bold {
    font-size: 28px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .title-default-left-bold {
    font-size: 26px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .title-default-left-bold {
    font-size: 24px;
  }
}
@media only screen and (max-width: 479px) {
  .title-default-left-bold {
    font-size: 22px;
  }
}
.title-default-left-bold a {
  color: #002147;
  transition: all 0.3s ease-out;
}
.title-default-left-bold a:hover {
  color: #fdc800;
}
.title-default-left-bold-lowhight {
  text-transform: capitalize;
  text-align: left;
  font-weight: 500;
  margin-bottom: 20px;
  color: #002147;
}
@media only screen and (max-width: 991px) {
  .title-default-left-bold-lowhight {
    font-size: 26px;
  }
}
@media only screen and (max-width: 767px) {
  .title-default-left-bold-lowhight {
    font-size: 24px;
  }
}
@media only screen and (max-width: 480px) {
  .title-default-left-bold-lowhight {
    font-size: 22px;
  }
}
.title-default-left-bold-lowhight a {
  color: #002147;
  transition: all 0.3s ease-out;
}
.title-default-left-bold-lowhight a:hover {
  color: #fdc800;
}
.title-news-left-bold {
  text-transform: capitalize;
  text-align: left;
  font-weight: 500;
  margin-bottom: 15px;
  color: #002147;
  font-size: 22px;
}
@media only screen and (max-width: 767px) {
  .title-news-left-bold {
    font-size: 20px;
  }
}
@media only screen and (max-width: 480px) {
  .title-news-left-bold {
    font-size: 18px;
  }
}
.title-news-left-bold a {
  color: #002147;
  transition: all 0.3s ease-out;
}
.title-news-left-bold a:hover {
  color: #fdc800;
}
.title-default-textPrimary-left {
  text-transform: capitalize;
  text-align: left;
  font-weight: 500;
  margin-bottom: 45px;
  color: #ffffff;
}
.about-title {
  font-size: 48px;
  color: #002147;
  margin-bottom: 15px;
  text-align: center;
}
@media (min-width: 991px) and (max-width: 1199px) {
  .about-title {
    font-size: 40px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .about-title {
    font-size: 36px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .about-title {
    font-size: 32px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .about-title {
    font-size: 28px;
  }
}
@media only screen and (max-width: 320px) {
  .about-title {
    font-size: 26px;
  }
}
.title-center {
  font-weight: 500;
  font-size: 36px;
  color: #002147;
  text-align: center;
}
@media (min-width: 991px) and (max-width: 1199px) {
  .title-center {
    font-size: 34px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .title-center {
    font-size: 32px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .title-center {
    font-size: 30px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .title-center {
    font-size: 28px;
  }
}
@media only screen and (max-width: 320px) {
  .title-center {
    font-size: 26px;
  }
}
.about-sub-title {
  width: 70%;
  margin: 0 auto;
  margin-bottom: 60px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .about-sub-title {
    width: 100%;
  }
}
.sub-title-full-width {
  margin-bottom: 80px;
  text-align: center;
}
@media (min-width: 991px) and (max-width: 1199px) {
  .sub-title-full-width {
    margin-bottom: 70px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .sub-title-full-width {
    margin-bottom: 60px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .sub-title-full-width {
    margin-bottom: 50px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .sub-title-full-width {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 320px) {
  .sub-title-full-width {
    margin-bottom: 30px;
  }
}
.sidebar-title {
  font-size: 22px;
  color: #002147;
  text-transform: capitalize;
  margin-bottom: 35px;
  position: relative;
  font-weight: 500;
}
@media (min-width: 480px) and (max-width: 1199px) {
  .sidebar-title {
    font-size: 19px;
  }
}
@media only screen and (max-width: 479px) {
  .sidebar-title {
    font-size: 18px;
  }
}
.sidebar-title a {
  color: #002147;
  transition: all 0.3s ease-out;
}
.sidebar-title a:hover {
  color: #fdc800;
}
.sidebar-title:before {
  content: "";
  height: 3px;
  width: 40px;
  position: absolute;
  left: 0;
  bottom: -15px;
  z-index: 1;
  background: #fdc800;
}
.apply-now-btn {
  text-align: center;
  color: #002147 !important;
  padding: 8px 0;
  width: 104px;
  background: #fdc800;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
  border: 2px solid #fdc800;
  transition: all 0.3s ease-out;
}
.apply-now-btn:hover {
  background: transparent;
  color: #ffffff !important;
}
.apply-now-btn2 {
  text-align: center;
  color: #002147;
  padding: 10px 0;
  width: 104px;
  background: #fdc800;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 700;
  display: inline-block;
  transition: all 0.3s ease-out;
}
.apply-now-btn2:hover {
  background: #002147;
  color: #ffffff !important;
}
.pricetable-btn {
  padding: 10px 0;
  text-align: center;
  color: #263238;
  height: 50px;
  width: 160px;
  background: #fdc800;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  border: 2px solid #fdc800;
  transition: all 0.3s ease-out;
}
@media (min-width: 480px) and (max-width: 767px) {
  .pricetable-btn {
    height: 45px;
    width: 140px;
    font-size: 15px;
    padding: 9px 0;
  }
}
@media only screen and (max-width: 479px) {
  .pricetable-btn {
    height: 40px;
    width: 130px;
    font-size: 14px;
    padding: 8px 0;
  }
}
.pricetable-btn:hover {
  background: transparent;
  color: #ffffff;
}
.default-big-btn {
  color: #ffffff;
  padding: 15px 0;
  background: #002147;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  border: none;
  width: 150px;
  text-align: center;
  transition: all 0.5s ease-out;
}
.default-big-btn:hover {
  background: #fdc800;
  color: #212121;
}
.default-full-width-btn {
  color: #ffffff;
  padding: 12px 0;
  background: #002147;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  border: none;
  width: 100%;
  border: 2px solid #002147;
  transition: all 0.5s ease-out;
}
.default-full-width-btn:hover {
  background: transparent;
  color: #002147;
}
.ghost-full-width-btn {
  color: #ffffff;
  padding: 14px 0;
  background: #002147;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  border: none;
  width: 100%;
  transition: all 0.5s ease-out;
}
.ghost-full-width-btn:hover {
  background: #fdc800;
  color: #002147;
}
.join-now-btn {
  color: #002147;
  padding: 13px 40px;
  background: #fdc800;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  border: none;
  border: 2px solid #fdc800;
  text-align: center;
  transition: all 0.5s ease-out;
}
@media (min-width: 480px) and (max-width: 767px) {
  .join-now-btn {
    padding: 10px 36px;
  }
}
@media only screen and (max-width: 479px) {
  .join-now-btn {
    padding: 8px 26px;
  }
}
.join-now-btn:hover {
  background: transparent;
  color: #ffffff;
}
.join-now-primary-btn {
  color: #ffffff;
  padding: 13px 0;
  background: #002147;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  border: none;
  width: 148px;
  border: 2px solid #002147;
  text-align: center;
  transition: all 0.5s ease-out;
}
.join-now-primary-btn:hover {
  background: transparent;
  color: #002147;
}
.sidebar-search-btn {
  color: #ffffff;
  padding: 14px 40px;
  background: #002147;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  border: none;
  text-align: center;
  transition: all 0.5s ease-out;
}
.sidebar-search-btn:hover {
  background: #fdc800;
  color: #002147;
}
.sidebar-search-btn-full {
  color: #ffffff;
  padding: 13px 0;
  width: 100%;
  background: #002147;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  border: none;
  border: 2px solid #002147;
  text-align: center;
  transition: all 0.5s ease-out;
}
.sidebar-search-btn-full:hover {
  background: transparent;
  color: #002147;
}
.view-all-accent-btn {
  text-align: center;
  color: #ffffff;
  padding: 12px 34px;
  background: #002147;
  text-transform: uppercase;
  font-size: 14px;
  display: inline-block;
  border: none;
  font-weight: 500;
  transition: all 0.5s ease-out;
}
.view-all-accent-btn:focus {
  color: #ffffff;
}
.view-all-accent-btn:hover {
  background: #fdc800;
  color: #212121;
}
.details-accent-btn {
  text-align: center;
  color: #002147;
  padding: 10px 30px;
  text-transform: uppercase;
  font-size: 14px;
  display: inline-block;
  border: none;
  font-weight: 500;
  border: 2px solid #fdc800;
  transition: all 0.5s ease-out;
}
.details-accent-btn:hover {
  background: #fdc800;
  color: #ffffff;
}
.view-all-primary-btn {
  text-align: center;
  color: #ffffff;
  padding: 12px 0;
  background: #002147;
  text-transform: uppercase;
  font-size: 14px;
  display: inline-block;
  border: none;
  width: 130px;
  transition: all 0.5s ease-out;
}
.view-all-primary-btn:hover {
  background: #fdc800;
  color: #030507;
}
.ghost-btn {
  border: 2px solid #fdc800;
  color: #ffffff;
  padding: 10px 30px;
  background: transparent;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  transition: all 0.3s ease-out;
}
.ghost-btn:hover {
  background: #fdc800;
  color: #002147;
}
.ghost-btn-big {
  border: 2px solid #fdc800;
  color: #ffffff;
  padding: 12px 30px;
  background: transparent;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  transition: all 0.3s ease-out;
}
.ghost-btn-big:hover {
  background: #fdc800;
  color: #002147;
}
.ghost-btn-big-primary {
  border: 2px solid #002147;
  color: #002147;
  padding: 12px 30px;
  background: transparent;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  transition: all 0.3s ease-out;
}
.ghost-btn-big-primary:hover {
  background: #002147;
  color: #ffffff;
}
.sidebar-ghost-btn {
  text-align: center;
  color: #ffffff;
  padding: 8px 0;
  width: 160px;
  font-style: italic;
  background: transparent;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
  display: inline-block;
  border: 2px solid #fdc800;
  transition: all 0.3s ease-out;
}
.sidebar-ghost-btn:hover {
  background: #fdc800;
  color: #002147;
}
.enroll-btn {
  color: #002147;
  padding: 18px 0;
  background: #fdc800;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  border: none;
  width: 100%;
  border: 2px solid #fdc800;
  text-align: center;
  transition: all 0.5s ease-out;
}
.enroll-btn:hover {
  background: transparent;
  color: #fdc800;
}
.download-btn {
  position: relative;
  color: #002147;
  padding: 18px 0;
  background: transparent;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  border: none;
  width: 100%;
  border: 1px solid #002147;
  text-align: center;
  transition: all 0.5s ease-out;
}
.download-btn:before {
  content: "\f019";
  position: absolute;
  font-family: FontAwesome;
  color: #002147;
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  left: 8%;
  z-index: 1;
}
.download-btn:hover {
  border: 1px solid #fdc800;
  background: #fdc800;
  color: #ffffff;
  transition: all 0.5s ease-out;
}
.download-btn:hover:before {
  color: #ffffff;
}
.default-white-btn {
  border: none;
  color: #002147;
  padding: 13px 30px;
  background: #ffffff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  transition: all 0.3s ease-out;
}
.default-white-btn:hover {
  color: #ffffff;
  background: #002147;
}
.isotop-btn {
  text-align: center;
  margin-bottom: 45px;
}
.isotop-btn:after {
  display: none;
}
.isotop-btn a {
  background: transparent;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 500;
  color: #002147;
  margin: 0 0 5px 0;
  text-transform: uppercase;
  display: inline-block;
  border: 1px solid #e0e0e0;
  transition: all 0.5s ease-out;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .isotop-btn a {
    padding: 10px 26px;
  }
}
.isotop-btn a:hover {
  border: 1px solid #002147;
  background: #002147;
  color: #ffffff;
}
.isotop-btn .current {
  border: 1px solid #002147;
  background: #002147;
  color: #ffffff;
}
.play-btn {
  z-index: 2;
  border: 3px solid #ffffff;
  border-radius: 50%;
  display: inline-block;
  height: 70px;
  width: 70px;
  line-height: 70px;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 480px) {
  .play-btn {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}
.play-btn i {
  color: #fdc800;
  font-size: 20px;
  transition: all 0.5s ease-out;
}
.play-btn:hover {
  border: 3px solid #fdc800;
}
.play-btn:hover i {
  color: #ffffff;
}
.isotop-btn-accent {
  text-align: center;
  margin-bottom: 45px;
}
.isotop-btn-accent:after {
  display: none;
}
.isotop-btn-accent a {
  background: transparent;
  padding: 10px 30px;
  font-size: 16px;
  font-weight: 700;
  color: #002147;
  margin: 0 0 5px 0;
  text-transform: uppercase;
  display: inline-block;
  border: 1px solid #e0e0e0;
  transition: all 0.5s ease-out;
}
.isotop-btn-accent a:hover {
  border: 1px solid #fdc800;
  background: #fdc800;
  color: #ffffff;
}
.isotop-btn-accent .current {
  border: 1px solid #fdc800;
  background: #fdc800;
  color: #ffffff;
}
.default-btn {
  color: #ffffff;
  padding: 13px 30px;
  background: #002147;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  border-radius: 30px;
  border: none;
  transition: all 0.3s ease-out;
}
.default-btn:hover {
  background: #00082e;
  color: #ffffff;
}
.quote-white-btn {
  border: none;
  color: #002147;
  padding: 10px 20px;
  background: #ffffff;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  display: inline-block;
  border-radius: 30px;
  transition: all 0.3s ease-out;
}
.quote-white-btn:hover {
  color: #212121;
}
.default-white-small-btn {
  color: #212121;
  padding: 8px 20px;
  background: #ffffff;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  display: inline-block;
  border-radius: 30px;
  transition: all 0.3s ease-out;
}
.default-white-small-btn:hover {
  color: #002147;
}
.submit-btn {
  color: #ffffff;
  padding: 12px 44px;
  background: #002147;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  border-radius: 30px;
  transition: all 0.3s ease-out;
}
.submit-btn:hover {
  background: #00082e;
  color: #ffffff;
}
.ghost-semi-color-btn {
  border: 2px solid #002147;
  color: #444444;
  padding: 10px 30px;
  background: transparent;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  display: inline-block;
  border-radius: 30px;
  transition: all 0.3s ease-out;
}
.ghost-semi-color-btn:hover {
  background: #002147;
  color: #ffffff;
}
.ghost-color-btn {
  border: 2px solid #002147;
  color: #002147;
  padding: 10px 44px;
  background: transparent;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  transition: all 0.3s ease-out;
}
.ghost-color-btn i {
  margin-left: 10px;
  color: #002147;
  transition: all 0.3s ease-out;
}
.ghost-color-btn:hover {
  background: #002147;
  color: #ffffff;
}
.ghost-color-btn:hover i {
  color: #ffffff;
}
.ghost-text-color-btn {
  border: 2px solid #bdbdbd;
  color: #002147;
  padding: 10px 44px;
  background: transparent;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  transition: all 0.3s ease-out;
}
.ghost-text-color-btn:hover {
  border: 2px solid #002147;
  background: #002147;
  color: #ffffff;
}
.ghost-on-hover-btn {
  border: 2px solid #002147;
  color: #ffffff;
  padding: 12px 40px;
  background: #002147;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  transition: all 0.3s ease-out;
}
.ghost-on-hover-btn:hover {
  background: transparent;
  color: #002147;
}
.owl-controls .owl-nav {
  position: absolute;
  top: -88px;
  right: -2px;
}
@media only screen and (max-width: 991px) {
  .owl-controls .owl-nav {
    top: -77px;
  }
}
@media only screen and (max-width: 480px) {
  .owl-controls .owl-nav {
    position: inherit;
  }
}
.owl-controls i {
  margin-left: 0;
  color: #ffffff;
  display: block;
  line-height: 20px;
  transition: all 0.3s ease-out;
}
.owl-controls .owl-prev {
  border: 1px solid #fdc800;
  background: #fdc800 !important;
  opacity: 1!important;
  font-size: 18px!important;
  margin: 0 1px!important;
  height: 30px;
  width: 30px;
  line-height: 30px;
  border-radius: 0!important;
  transition: all 0.3s ease-out;
}
@media only screen and (max-width: 480px) {
  .owl-controls .owl-prev {
    left: -15px;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    position: absolute;
  }
}
.owl-controls .owl-prev:hover {
  background: #002147 !important;
  border: 1px solid #002147;
  transition: all 0.3s ease-out;
}
.owl-controls .owl-prev:hover i {
  color: #ffffff;
}
.owl-controls .owl-next {
  border: 1px solid #fdc800;
  right: -45px;
  background: #fdc800 !important;
  opacity: 1!important;
  font-size: 18px!important;
  margin: 0 4px!important;
  height: 30px;
  width: 30px;
  line-height: 30px;
  border-radius: 0!important;
  transition: all 0.3s ease-out;
}
@media only screen and (max-width: 480px) {
  .owl-controls .owl-next {
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    position: absolute;
  }
}
.owl-controls .owl-next:hover {
  background: #002147 !important;
  border: 1px solid #002147;
  transition: all 0.3s ease-out;
}
.owl-controls .owl-next:hover i {
  color: #ffffff;
  transition: all 0.3s ease-out;
}
#scrollUp {
  background-color: rgba(253, 200, 0, 0.8);
  bottom: 5px;
  color: #ffffff;
  display: block;
  font-size: 18px;
  height: 45px;
  line-height: 45px;
  position: fixed;
  right: 20px;
  text-align: center;
  text-decoration: none;
  transition: all 0.5s cubic-bezier(0, 0, 0, 1) 0s;
  width: 45px;
  z-index: 99999;
  border-radius: 50%;
}
#scrollUp i {
  color: #ffffff;
}
#scrollUp:hover,
#scrollUp:focus {
  background-color: rgba(255, 255, 255, 0.8);
}
#scrollUp:hover i,
#scrollUp:focus i {
  color: #002147;
}
#preloader {
  /* background: #002147 url('img/preloader.gif') no-repeat scroll center center; */
  height: 100%;
  left: 0;
  overflow: visible;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999999;
}
.inner-page-banner-area {
  text-align: left;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: block;
  padding: 100px 0 115px;
}
.inner-page-banner-area:after {
  position: absolute;
  content: "";
  background: rgba(0, 0, 0, 0.7);
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.inner-page-banner-area .pagination-area {
  position: relative;
  z-index: 1;
}
.inner-page-banner-area .pagination-area h2 {
  text-transform: capitalize;
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 48px;
  color: #ffffff;
}
@media (min-width: 480px) and (max-width: 767px) {
  .inner-page-banner-area .pagination-area h2 {
    font-size: 42px;
  }
}
@media only screen and (max-width: 479px) {
  .inner-page-banner-area .pagination-area h2 {
    font-size: 32px;
  }
}
.inner-page-banner-area .pagination-area ul li {
  display: inline-block;
  color: #ffffff;
  font-size: 14px;
}
.inner-page-banner-area .pagination-area ul li a {
  color: #fdc800;
  transition: all 0.5s ease-out;
}
.inner-page-banner-area .pagination-area ul li a:hover {
  color: #ffffff;
}
.pagination-center {
  text-align: center;
  margin-top: 43px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .pagination-center {
    margin-top: 33px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .pagination-center {
    margin-top: 23px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .pagination-center {
    margin-top: 13px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .pagination-center {
    margin-top: 03px;
  }
}
@media only screen and (max-width: 320px) {
  .pagination-center {
    margin-top: 0px;
  }
}
.pagination-center li {
  display: inline-block;
}
.pagination-center li a {
  width: 42px;
  font-size: 16px;
  color: #ffffff;
  height: 40px;
  background: #002147;
  display: block;
  line-height: 40px;
  text-align: center;
  transition: all 0.5s ease-out;
}
.pagination-center li a:hover {
  background: #fdc800;
}
.pagination-center .active a {
  background: #fdc800;
}
.pagination-left {
  text-align: left;
  margin-top: 20px;
}
.pagination-left li {
  display: inline-block;
}
.pagination-left li a {
  width: 42px;
  font-size: 16px;
  color: #ffffff;
  height: 40px;
  background: #002147;
  display: block;
  line-height: 40px;
  text-align: center;
  transition: all 0.5s ease-out;
}
.pagination-left li a:hover {
  background: #fdc800;
}
.pagination-left .active a {
  background: #fdc800;
}
.overlay-default {
  position: relative;
}
.overlay-default:before {
  content: "";
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 6;
  top: 0;
  left: 0;
  right: 0;
}
.mfp-fade.mfp-bg {
  opacity: 0;
  transition: all 0.15s ease-out;
}
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  transition: all 0.15s ease-out;
}
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}
/*=======================================================================
[03] Header Area
=========================================================================*/
.main-menu-area {
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 0;
  z-index: 10;
}
.main-menu-area .logo-area {
  margin: 21px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .main-menu-area .logo-area {
    margin: 25px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .main-menu-area .logo-area {
    margin: 22px 0;
  }
}
.main-menu-area .apply-btn-area {
  margin: 22px 0;
  text-align: right;
}
.login-form {
  position: absolute;
  z-index: 999;
  top: 38px;
  right: -20px;
  width: 540px;
  height: 444px;
  text-align: left;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  padding: 40px;
}
@media only screen and (max-width: 767px) {
  .login-form {
    margin-bottom: 15px;
  }
}
.login-form label {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
  color: #002147;
}
.login-form input {
  height: 40px;
  padding: 0 10px;
  width: 100%;
  border-radius: 0;
  border: 1px solid #bdbdbd;
  margin-bottom: 15px;
  color: #002147;
  font-weight: 500;
  font-size: 15px;
}
.login-form span {
  margin-right: 15px;
  color: #002147;
}
@media (min-width: 768px) and (max-width: 991px) {
  .login-form span {
    font-size: 12px;
  }
}
@media only screen and (max-width: 479px) {
  .login-form span {
    display: inline-block;
    margin-left: 0;
    margin-top: 15px;
  }
}
.login-form span input {
  width: inherit;
  height: inherit;
  margin-right: 10px;
  padding: 30px;
}
nav#desktop-nav ul {
  text-align: right;
}
nav#desktop-nav ul .active > a {
  color: #fdc800;
}
nav#desktop-nav ul li {
  display: inline-block;
  position: relative;
}
nav#desktop-nav ul li:hover ul {
  opacity: 1;
  transform: scaleY(1);
  visibility: visible;
}
nav#desktop-nav ul li a {
  padding: 30px 20px;
  display: block;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
  color: #ffffff;
  transition: all 0.5s ease-out;
}
@media (min-width: 992px) and (max-width: 1199px) {
  nav#desktop-nav ul li a {
    padding: 30px 12px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  nav#desktop-nav ul li a {
    padding: 30px 6px;
  }
}
nav#desktop-nav ul li a:hover {
  color: #fdc800;
}
nav#desktop-nav ul li > ul {
  margin-top: 0;
  text-align: left;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 101%;
  transform: scaleY(0);
  transform-origin: 0 0 0;
  transition: all 0.5s ease 0s;
  width: 180px;
  z-index: 99999;
  background: #002147;
  padding: 0;
  left: 5px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  nav#desktop-nav ul li > ul {
    left: -3px;
  }
}
@media only screen and (max-width: 991px) {
  nav#desktop-nav ul li > ul {
    left: -9px;
  }
}
nav#desktop-nav ul li > ul > li {
  padding: 0;
  display: block;
  border-bottom: 1px solid #dddddd;
}
nav#desktop-nav ul li > ul > li > a {
  padding: 10px 10px 10px 15px;
  display: block;
  color: #ffffff;
}
nav#desktop-nav ul li > ul > li:last-child {
  border-bottom: none;
}
nav#desktop-nav ul li > ul > li:hover,
nav#desktop-nav ul li > ul > li .active {
  background: #1a3b61;
  transition: all 0.5s ease-out;
}
nav#desktop-nav ul li > ul > li:hover a,
nav#desktop-nav ul li > ul > li .active a {
  color: #fdc800;
}
nav#desktop-nav ul li > ul > li:hover a:before,
nav#desktop-nav ul li > ul > li .active a:before {
  opacity: 0;
}
nav#desktop-nav ul li .mega-menu-area {
  padding: 18px 15px;
  width: 700px;
  left: 5px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  nav#desktop-nav ul li .mega-menu-area {
    left: -258px;
  }
}
@media only screen and (max-width: 991px) {
  nav#desktop-nav ul li .mega-menu-area {
    left: -272px;
  }
}
nav#desktop-nav ul li .mega-menu-area > li {
  width: 154px;
  border-bottom: none;
  display: inline-block;
  margin-right: 14px;
}
nav#desktop-nav ul li .mega-menu-area > li:hover {
  background: transparent!important;
}
nav#desktop-nav ul li .mega-menu-area > li:hover > a {
  color: #ffffff;
  transition: all 0.5s ease-out;
}
nav#desktop-nav ul li .mega-menu-area > li:hover > a:hover {
  color: #fdc800;
  background: #1a3b61;
  padding-left: 10px;
}
nav#desktop-nav ul li .mega-menu-area > li a {
  padding: 8px 0;
  display: block;
  border-bottom: 1px solid #1a3b61;
}
nav#desktop-nav ul li .mega-menu-area > li a:last-child {
  border-bottom: none;
}
nav#desktop-nav ul li .mega-menu-area > li:last-child {
  margin-right: 0;
}
nav#desktop-nav li.has-child-menu {
  position: relative;
}
nav#desktop-nav li.has-child-menu > a {
  color: #ffffff;
}
nav#desktop-nav li.has-child-menu > a:after {
  font-family: 'FontAwesome';
  content: "\f105";
  float: right;
}
nav#desktop-nav li.has-child-menu > ul.thired-level {
  position: absolute;
  left: 180px;
  top: 0;
  opacity: 0;
  transform: scaleY(0);
  transform-origin: 0 0 0;
  transition: all 0.5s ease 0s;
}
nav#desktop-nav li.has-child-menu > ul.thired-level li a {
  color: #ffffff;
  transition: all 0.3s ease-out;
}
nav#desktop-nav li.has-child-menu > ul.thired-level li:hover a {
  color: #fdc800;
}
nav#desktop-nav li.has-child-menu:hover ul.thired-level {
  opacity: 1;
  transform: scaleY(1);
  visibility: visible;
}
.main-menu-area {
  transition: all 1s ease-out;
}
.main-menu-area .header-search {
  margin: 30px 0;
  text-align: right;
}
.main-menu-area .header-search .search-form {
  display: none;
  position: absolute;
  bottom: -40px;
  right: 15px;
  z-index: 10;
  color: #002147;
  height: 40px;
  width: 230px;
  background: #ffffff;
  border: 1px solid #002147;
  padding: 5px 18px 5px 10px;
  box-shadow: 0 0 0.5px -1.5px rgba(153, 157, 163, 0.4);
}
.main-menu-area .header-search a i {
  color: #fdc800;
  border-left: 1px solid #d3d3d3;
  padding-left: 30px;
}
.stick {
  background: rgba(0, 33, 71, 0.9);
  border-bottom: 2px solid #002147;
  position: fixed;
  transition: all 1s ease-out;
}
#header1 {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 100;
}
.slider1-area.index1 {
  margin-top: 75px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .slider1-area.index1 {
    margin-top: 74px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .slider1-area.index1 {
    margin-top: 73px;
  }
}
@media only screen and (max-width: 767px) {
  .slider1-area.index1 {
    margin-top: 0;
  }
}
.header2-area .header-top-area {
  background: #002147;
}
.header2-area .header-top-area .header-top-left {
  margin: 7px 0;
}
.header2-area .header-top-area .header-top-left ul li {
  display: inline-block;
  text-transform: lowercase;
  font-size: 13px;
  border-right: 1px solid #bdbdbd;
  margin-right: 15px;
  padding-right: 15px;
}
.header2-area .header-top-area .header-top-left ul li i {
  padding-right: 10px;
  color: #fdc800;
  font-size: 14px;
}
.header2-area .header-top-area .header-top-left ul li a {
  color: #d0d6dd;
  transition: all 0.3s ease-out;
}
.header2-area .header-top-area .header-top-left ul li:last-child {
  border-right: 0;
  padding-right: 0;
  margin-right: 0;
}
.header2-area .header-top-area .header-top-left ul li:hover a {
  color: #fdc800;
  transition: all 0.3s ease-out;
}
.header2-area .header-top-area .header-top-right {
  text-align: right;
}
.header2-area .header-top-area .header-top-right ul li {
  font-size: 13px;
  padding-right: 0;
  display: inline-block;
  margin-left: 15px;
  position: relative;
}
.header2-area .header-top-area .header-top-right ul li .login-btn-area {
  padding: 9px 15px;
  display: block;
  color: #bcc8d6;
  transition: all 0.3s ease-out;
}
.header2-area .header-top-area .header-top-right ul li .login-btn-area i {
  padding-right: 10px;
  font-size: 14px;
  color: #fdc800;
  transition: all 0.3s ease-out;
}
.header2-area .header-top-area .header-top-right ul li .login-btn-area:hover {
  color: #fdc800;
  transition: all 0.3s ease-out;
}
.header2-area .header-top-area .header-top-right ul li .apply-btn-area {
  text-align: right;
}
.header2-area .header-search a i {
  color: #444444;
  border-left: 1px solid #d3d3d3;
  padding-left: 30px;
  transition: all 0.3s ease-out;
}
.header2-area .header-search a:hover i {
  color: #fdc800;
  transition: all 0.3s ease-out;
}
.header2-area nav#desktop-nav > ul > li > a {
  padding: 30px 25px;
  color: #002147;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .header2-area nav#desktop-nav > ul > li > a {
    padding: 30px 18px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .header2-area nav#desktop-nav > ul > li > a {
    padding: 30px 6px;
  }
}
.header2-area nav#desktop-nav > ul > li ul {
  left: 10px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .header2-area nav#desktop-nav > ul > li ul {
    left: 3px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .header2-area nav#desktop-nav > ul > li ul {
    left: -9px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .header2-area nav#desktop-nav > ul > li .mega-menu-area {
    left: -267px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .header2-area nav#desktop-nav > ul > li .mega-menu-area {
    left: -272px;
  }
}
.header2-area .stick {
  background: rgba(255, 255, 255, 0.9);
  transition: all 1s ease-out;
}
.header3-area {
  position: fixed;
  z-index: 15;
  left: 0;
  right: 0;
}
.header3-area .header-top-area {
  background: transparent;
}
.header3-area .header-top-area .top-bar-border {
  position: relative;
}
.header3-area .header-top-area .top-bar-border:before {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #656565;
}
.header3-area .header-top-area .top-bar-border:after {
  clear: both;
  content: "";
  display: block;
}
.header3-area .header-top-area .header-top-left {
  padding-top: 8px;
}
.header3-area .header-top-area .header-top-left ul li {
  display: inline-block;
  text-transform: lowercase;
  font-size: 13px;
  border-right: 1px solid #bdbdbd;
  margin-right: 15px;
  padding-right: 15px;
}
.header3-area .header-top-area .header-top-left ul li i {
  padding-right: 10px;
  color: #fdc800;
  font-size: 14px;
}
.header3-area .header-top-area .header-top-left ul li a {
  color: #d0d6dd;
  transition: all 0.3s ease-out;
}
.header3-area .header-top-area .header-top-left ul li:last-child {
  border-right: 0;
  padding-right: 0;
  margin-right: 0;
}
.header3-area .header-top-area .header-top-left ul li:hover a {
  color: #fdc800;
  transition: all 0.3s ease-out;
}
.header3-area .header-top-area .header-top-right {
  text-align: right;
}
.header3-area .header-top-area .header-top-right ul li {
  position: relative;
  font-size: 13px;
  padding-right: 0;
  display: inline-block;
  margin-left: 15px;
}
.header3-area .header-top-area .header-top-right ul li a {
  color: #bcc8d6;
  transition: all 0.3s ease-out;
}
.header3-area .header-top-area .header-top-right ul li a i {
  padding-right: 10px;
  font-size: 14px;
  color: #fdc800;
  transition: all 0.3s ease-out;
}
.header3-area .header-top-area .header-top-right ul li a:hover {
  color: #fdc800;
  transition: all 0.3s ease-out;
}
.header3-area .header-top-area .header-top-right ul li .apply-btn-area {
  text-align: right;
}
.header3-area .header-divider {
  border-bottom: 1px solid #656565;
}
.header3-area nav#desktop-nav > ul > li > a {
  padding: 30px 25px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .header3-area nav#desktop-nav > ul > li > a {
    padding: 30px 18px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .header3-area nav#desktop-nav > ul > li > a {
    padding: 30px 6px;
  }
}
.header3-area nav#desktop-nav > ul > li ul {
  left: 10px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .header3-area nav#desktop-nav > ul > li ul {
    left: 3px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .header3-area nav#desktop-nav > ul > li ul {
    left: -9px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .header3-area nav#desktop-nav > ul > li .mega-menu-area {
    left: -267px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .header3-area nav#desktop-nav > ul > li .mega-menu-area {
    left: -272px;
  }
}
.header4-area .header-top-area {
  padding: 10px 0 8px;
  background: #ffffff;
}
.header4-area .header-top-area .header-top-right {
  text-align: right;
}
.header4-area .header-top-area .header-top-right ul li {
  position: relative;
  font-size: 16px;
  padding-right: 0;
  display: inline-block;
  margin-left: 15px;
  padding-right: 15px;
  border-right: 1px solid #212121;
}
.header4-area .header-top-area .header-top-right ul li:nth-child(3n) {
  border-right: none;
}
.header4-area .header-top-area .header-top-right ul li:nth-child(4n) {
  border-right: none;
  padding-right: 0;
}
@media (min-width: 768px) and (max-width: 991px) {
  .header4-area .header-top-area .header-top-right ul li:nth-child(4n) {
    display: none;
  }
}
.header4-area .header-top-area .header-top-right ul li i {
  padding-right: 10px;
  font-size: 18px;
  color: #fdc800;
  transition: all 0.3s ease-out;
}
.header4-area .header-top-area .header-top-right ul li a {
  color: #002147;
  transition: all 0.3s ease-out;
}
.header4-area .header-top-area .header-top-right ul li a:hover {
  color: #fdc800;
  transition: all 0.3s ease-out;
}
.header4-area .header-top-area .header-top-right ul li .apply-btn-area {
  text-align: right;
}
.header4-area .header-top-area .header-top-right .login-form {
  position: absolute;
  z-index: 999;
  top: 46px;
  right: -20px;
  width: 540px;
  height: 444px;
  text-align: left;
  background: #ffffff;
  border: 1px solid #bdbdbd;
  padding: 40px;
}
@media only screen and (max-width: 767px) {
  .header4-area .header-top-area .header-top-right .login-form {
    margin-bottom: 15px;
  }
}
.header4-area .header-top-area .header-top-right .login-form label {
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 12px;
  color: #002147;
}
.header4-area .header-top-area .header-top-right .login-form input {
  height: 40px;
  padding: 0 10px;
  width: 100%;
  border-radius: 0;
  border: 1px solid #bdbdbd;
  margin-bottom: 15px;
  color: #002147;
  font-weight: 500;
  font-size: 15px;
}
.header4-area .header-top-area .header-top-right .login-form span {
  font-size: 13px;
  margin-right: 15px;
  color: #002147;
}
@media (min-width: 768px) and (max-width: 991px) {
  .header4-area .header-top-area .header-top-right .login-form span {
    font-size: 12px;
  }
}
@media only screen and (max-width: 479px) {
  .header4-area .header-top-area .header-top-right .login-form span {
    display: inline-block;
    margin-left: 0;
    margin-top: 15px;
  }
}
.header4-area .header-top-area .header-top-right .login-form span input {
  width: inherit;
  height: inherit;
  margin-right: 10px;
  padding: 30px;
}
.header4-area nav#desktop-nav > ul {
  text-align: left;
}
.header4-area nav#desktop-nav > ul > li > a {
  padding: 20px 35px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .header4-area nav#desktop-nav > ul > li > a {
    padding: 20px 24px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .header4-area nav#desktop-nav > ul > li > a {
    padding: 20px 11px;
  }
}
.header4-area nav#desktop-nav > ul > li ul {
  left: 20px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .header4-area nav#desktop-nav > ul > li ul {
    left: 9px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .header4-area nav#desktop-nav > ul > li ul {
    left: -3px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  
}
@media (min-width: 768px) and (max-width: 991px) {
  .header4-area nav#desktop-nav > ul > li .mega-menu-area {
    left: -68px;
  }
}
.header4-area .header-search {
  margin: 20px 0;
}
@media only screen and (max-width: 767px) {
  header {
    display: none;
  }
}
.mobile-menu-area {
  display: none;
}
.mean-container .mean-bar {
  float: none;
}
@media only screen and (max-width: 767px) {
  .mean-container .mean-bar a {
    padding: 6px 16px;
    display: inline-block;
  }
}
@media only screen and (max-width: 767px) {
  .mean-container .mean-bar .meanmenu-reveal {
    margin-top: 10px;
  }
}
.mean-container .mean-bar:after {
  content: "";
  clear: both;
  display: block;
}
.mean-container .mean-nav ul li a {
  color: #212121;
  font-weight: 700;
  transition: all 0.5s ease-out;
}
.mean-container .mean-nav ul li a:hover {
  color: #002147;
  transition: all 0.5s ease-out;
}
.mean-container .mean-nav > ul:after {
  content: '';
  display: block;
  clear: both;
}
.mean-container .mean-nav > ul {
  overflow-y: scroll;
}
.mean-container .mean-bar a.logo-mobile-menu {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  box-sizing: border-box;
}
.mean-container a.meanmenu-reveal {
  position: absolute;
}
.mean-bar + div#wrapper {
  padding-top: 53px;
}
.mean-container .mean-nav ul li li a {
  color: #212121;
  opacity: 1;
}
/*=======================================================================
[04] Slider Area
=========================================================================*/
.slider1-area .nivo-controlNav {
  display: none;
}
.slider1-area .nivo-directionNav a.nivo-prevNav {
  left: 8px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-out;
}
.slider1-area .nivo-directionNav a.nivo-prevNav:before {
  content: "\f053";
  font: normal normal normal 14px/1 FontAwesome;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  margin: 0;
  position: absolute;
  text-align: center;
  top: 18px;
  z-index: 9;
  height: 10px;
  width: 10px;
  left: 19px;
  transition: all 0.5s ease-out;
}
.slider1-area .nivo-directionNav a.nivo-prevNav:hover {
  background-color: rgba(0, 33, 71, 0.6);
  transition: all 0.5s ease-out;
}
.slider1-area .nivo-directionNav a.nivo-prevNav:hover:before {
  background: transparent;
  transition: all 0.5s ease-out;
}
.slider1-area .nivo-directionNav a.nivo-nextNav {
  right: 8px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.5s ease-out;
}
.slider1-area .nivo-directionNav a.nivo-nextNav:before {
  content: "\f054";
  font: normal normal normal 14px/1 FontAwesome;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  margin: 0;
  position: absolute;
  text-align: center;
  top: 18px;
  z-index: 9;
  height: 10px;
  width: 10px;
  right: 19px;
  transition: all 0.5s ease-out;
}
.slider1-area .nivo-directionNav a.nivo-nextNav:hover {
  background-color: rgba(0, 33, 71, 0.6);
  transition: all 0.5s ease-out;
}
.slider1-area .nivo-directionNav a.nivo-nextNav:hover:before {
  background: transparent;
  transition: all 0.5s ease-out;
}
.slider1-area .nivo-caption {
  opacity: 1;
  top: -40px;
}
@media only screen and (max-width: 991px) {
  .slider1-area .nivo-caption {
    top: 0;
  }
}
.slider1-area .slide-1 h1,
.slider1-area .slide-2 h1,
.slider1-area .slide-3 h1 {
  color: #ffffff;
  text-transform: capitalize;
  font-size: 56px;
  margin-bottom: 10px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .slider1-area .slide-1 h1,
  .slider1-area .slide-2 h1,
  .slider1-area .slide-3 h1 {
    font-size: 42px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .slider1-area .slide-1 h1,
  .slider1-area .slide-2 h1,
  .slider1-area .slide-3 h1 {
    font-size: 38px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .slider1-area .slide-1 h1,
  .slider1-area .slide-2 h1,
  .slider1-area .slide-3 h1 {
    margin-bottom: 20px;
    font-size: 32px;
  }
}
@media only screen and (max-width: 479px) {
  .slider1-area .slide-1 h1,
  .slider1-area .slide-2 h1,
  .slider1-area .slide-3 h1 {
    font-size: 28px;
    margin-bottom: 10px;
    line-height: 1.25;
  }
}
@media only screen and (max-width: 319px) {
  .slider1-area .slide-1 h1,
  .slider1-area .slide-2 h1,
  .slider1-area .slide-3 h1 {
    font-size: 24px;
  }
}
.slider1-area .slide-1 p,
.slider1-area .slide-2 p,
.slider1-area .slide-3 p {
  font-size: 16px;
  margin-bottom: 50px;
}
@media (min-width: 991px) and (max-width: 1199px) {
  .slider1-area .slide-1 p,
  .slider1-area .slide-2 p,
  .slider1-area .slide-3 p {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .slider1-area .slide-1 p,
  .slider1-area .slide-2 p,
  .slider1-area .slide-3 p {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .slider1-area .slide-1 p,
  .slider1-area .slide-2 p,
  .slider1-area .slide-3 p {
    display: none;
  }
}
@media only screen and (max-width: 479px) {
  .slider1-area .slide-1 .slider-btn-area,
  .slider1-area .slide-2 .slider-btn-area,
  .slider1-area .slide-3 .slider-btn-area {
    display: none;
  }
}
.slider3-area .nivo-caption {
  opacity: 1;
  top: 0;
}
@media only screen and (max-width: 1199px) {
  .slider3-area .nivo-caption {
    top: 0;
  }
}
@media only screen and (max-width: 991px) {
  .slider3-area .nivo-caption .slide-1 p,
  .slider3-area .nivo-caption .slide-2 p,
  .slider3-area .nivo-caption .slide-3 p {
    display: none;
  }
}
/*=======================================================================
[05] About Area
=========================================================================*/
.about1-area {
  padding: 81px 0 0;
  text-align: center;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .about1-area {
    padding: 74px 0 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .about1-area {
    padding: 67px 0 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .about1-area {
    padding: 53px 0 0;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .about1-area {
    padding: 44px 0 0;
  }
}
@media only screen and (max-width: 320px) {
  .about1-area {
    padding: 35px 0 0;
  }
}
.about-img-holder {
  text-align: center;
}
.about-img-holder img {
  margin: 0 auto;
}
.about2-area {
  padding: 81px 0 94px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .about2-area {
    padding: 74px 0 84px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .about2-area {
    padding: 66px 0 74px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .about2-area {
    padding: 57px 0 34px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .about2-area {
    padding: 49px 0 23px;
  }
}
@media only screen and (max-width: 320px) {
  .about2-area {
    padding: 39px 0 14px;
  }
}
.about-img-holder {
  text-align: center;
}
.about-img-holder img {
  margin: 0 auto;
}
.about-page1-area {
  padding: 88px 0 100px;
  background: #f5f5f5;  
}
@media (min-width: 992px) and (max-width: 1199px) {
  .about-page1-area {
    padding: 78px 0 53px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .about-page1-area {
    padding: 68px 0 43px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .about-page1-area {
    padding: 58px 0 70px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .about-page1-area {
    padding: 48px 0 60px;
  }
}
@media only screen and (max-width: 320px) {
  .about-page1-area {
    padding: 38px 0 50px;
  }
}
.about-page1-inner .about-page-content-holder .content-box {
  margin-bottom: 30px;
}
.about-page1-inner .about-page-content-holder .content-box h2 {
  font-size: 30px;
  color: #002147;
  margin-bottom: 15px;
  font-weight: 700;
}
.about-page1-inner .about-page-content-holder .content-box p {
  color: #444444;
}
.about-page1-inner .about-page-img-holder {
  margin-top: 12px;
}
.about-page2-area {
  padding: 88px 0 100px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .about-page2-area {
    padding: 78px 0 90px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .about-page2-area {
    padding: 68px 0 72px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .about-page2-area {
    padding: 58px 0 70px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .about-page2-area {
    padding: 48px 0 60px;
  }
}
@media only screen and (max-width: 320px) {
  .about-page2-area {
    padding: 38px 0 50px;
  }
}
.about-page2-inner h2 {
  margin-bottom: 20px;
}
.about-page2-inner p {
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .about-page2-inner ul {
    margin-bottom: 30px;
  }
}
.about-page2-inner ul li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: 500;
}
.about-page2-inner ul li a {
  color: #002147;
}
.about-page2-inner ul li:last-child {
  margin-bottom: 0;
}
.about-page2-inner ul li:before {
  content: "\f14a";
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  color: #fdc800;
  font-family: FontAwesome;
}
.about-page2-inner .video-area {
  margin-top: 10px;
}
.about-page3-area {
  padding: 88px 0 73px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .about-page3-area {
    padding: 78px 0 63px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .about-page3-area {
    padding: 68px 0 54px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .about-page3-area {
    padding: 58px 0 43px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .about-page3-area {
    padding: 48px 0 34px;
  }
}
@media only screen and (max-width: 320px) {
  .about-page3-area {
    padding: 38px 0 24px;
  }
}
.about-page3-inner .about-box h2 {
  font-size: 30px;
  margin-bottom: 35px;
}
.about-page3-inner .about-box h2 a {
  color: #002147;
}
.about-slider-area {
  padding: 100px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .about-slider-area {
    padding: 90px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .about-slider-area {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .about-slider-area {
    padding: 70px 0 55px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .about-slider-area {
    padding: 60px 0 45px;
  }
}
@media only screen and (max-width: 320px) {
  .about-slider-area {
    padding: 50px 0 35px;
  }
}
.about-slider-area .slider1-area {
  margin-bottom: 45px;
}
.about-slider-area .slider1-area .nivo-directionNav a.nivo-nextNav {
  background-color: rgba(253, 200, 0, 0.9);
  width: 40px;
  height: 60px;
  border-radius: 0;
  line-height: 40px;
  right: 0!important;
}
.about-slider-area .slider1-area .nivo-directionNav a.nivo-nextNav:before {
  color: #ffffff;
  right: 16px;
  top: 22px;
}
.about-slider-area .slider1-area .nivo-directionNav a.nivo-nextNav:hover {
  background-color: rgba(0, 33, 71, 0.8);
}
.about-slider-area .slider1-area .nivo-directionNav a.nivo-prevNav {
  background-color: rgba(253, 200, 0, 0.9);
  width: 40px;
  height: 60px;
  border-radius: 0;
  line-height: 40px;
  left: 0!important;
}
.about-slider-area .slider1-area .nivo-directionNav a.nivo-prevNav:before {
  color: #ffffff;
  left: 16px;
  top: 22px;
}
.about-slider-area .slider1-area .nivo-directionNav a.nivo-prevNav:hover {
  background-color: rgba(0, 33, 71, 0.8);
}
/*=======================================================================
[06] Service Area
=========================================================================*/
.service-box1 {
  border-right: 1px solid #aaaaaa;
  padding: 30px 30px 15px 40px;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  background-color: rgba(0, 33, 71, 0.8);
}
@media (min-width: 992px) and (max-width: 1199px) {
  .service-box1 {
    padding: 30px 25px 15px;
  }
}
@media only screen and (max-width: 767px) {
  .service-box1 {
    margin-bottom: 5px;
  }
}
@media only screen and (max-width: 991px) {
  .service-box1 {
    background-color: #002147;
    padding: 20px 20px 15px;
  }
}
.service-box1:last-child {
  border-right: none;
}
.service-box1 .service-box-content {
  -moz-flex: 3;
  flex: 3 1;
}
.service-box1 .service-box-content h3 {
  font-size: 22px;
  margin-bottom: 10px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .service-box1 .service-box-content h3 {
    font-size: 20px;
  }
}
.service-box1 .service-box-content h3 a {
  color: #ffffff;
  transition: all 0.3s ease-out;
}
.service-box1 .service-box-content p {
  color: #ffffff;
  font-weight: 400;
}
.service-box1 .service-box-icon {
  text-align: center;
  padding-top: 10px;
  -moz-flex: 1;
  flex: 1 1;
}
.service-box1 .service-box-icon i {
  color: #fdc800;
  font-size: 36px;
  transform: scale(1);
  transition: all 0.5s ease-out;
}
.service-box1:hover .service-box-content h3 a {
  color: #fdc800;
  transition: all 0.3s ease-out;
}
.service-box1:hover .service-box-content p {
  color: #ffffff;
  transition: all 0.3s ease-out;
}
.service-box1:hover .service-box-icon i {
  transform: scale(1.2);
  transition: all 0.5s ease-out;
}
.service-box2 {
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .service-box2 {
    margin-bottom: 30px;
  }
}
.service-box2 .service-box-icon {
  margin-bottom: 18px;
}
.service-box2 .service-box-icon a {
  border-radius: 50%;
  height: 90px;
  width: 90px;
  display: block;
  background: #eeeeee;
  line-height: 100px;
  margin: 0 auto;
  transition: all 0.5s ease-out;
}
.service-box2 .service-box-icon a i {
  color: #002147;
  font-size: 36px;
}
.service-box2 h3 {
  font-size: 22px;
  margin-bottom: 15px;
  font-weight: 500;
}
.service-box2 h3 a {
  color: #002147;
  transition: all 0.5s ease-out;
}
.service-box2 h3 a:hover {
  color: #fdc800;
}
.service-box2 p {
  width: 80%;
  margin: 0 auto;
}
@media only screen and (max-width: 991px) {
  .service-box2 p {
    width: 100%;
  }
}
.service-box2:hover .service-box-icon a {
  background: #fdc800;
  transition: all 0.5s ease-out;
}
.service-box3 {
  text-align: center;
}
@media only screen and (max-width: 991px) {
  .service-box3 {
    margin-bottom: 45px;
  }
}
@media only screen and (max-width: 479px) {
  .service-box3 {
    margin-bottom: 30px;
  }
}
.service-box3 .service-box-icon {
  margin-bottom: 18px;
}
.service-box3 .service-box-icon a i {
  color: #002147;
  transition: all 0.5s ease-out;
  font-size: 40px;
}
.service-box3 h3 {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 15px;
}
.service-box3 h3 a {
  color: #002147;
  transition: all 0.5s ease-out;
}
.service-box3 h3 a:hover {
  color: #fdc800;
}
.service-box3:hover .service-box-icon a i {
  color: #fdc800;
  transition: all 0.5s ease-out;
}
.service-box4 {
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .service-box4 {
    margin-bottom: 30px;
  }
}
.service-box4 .service-box-icon {
  margin-bottom: 18px;
}
.service-box4 .service-box-icon a {
  border-radius: 50%;
  height: 90px;
  width: 90px;
  display: block;
  background: #ffffff;
  line-height: 100px;
  margin: 0 auto;
  transition: all 0.5s ease-out;
}
.service-box4 .service-box-icon a i {
  color: #002147;
  font-size: 36px;
}
.service-box4 h3 {
  font-size: 22px;
  margin-bottom: 15px;
  font-weight: 500;
}
.service-box4 h3 a {
  color: #002147;
  transition: all 0.5s ease-out;
}
.service-box4 h3 a:hover {
  color: #fdc800;
}
.service-box4 p {
  width: 80%;
  margin: 0 auto;
}
@media only screen and (max-width: 991px) {
  .service-box4 p {
    width: 100%;
  }
}
.service-box4:hover .service-box-icon a {
  background: #fdc800;
  transition: all 0.5s ease-out;
}
.service1-area {
  position: relative;
}
.service1-area .service1-inner-area {
  position: absolute;
  top: -153px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9;
}
@media only screen and (max-width: 1199px) {
  .service1-area .service1-inner-area {
    top: -150px;
  }
}
@media only screen and (max-width: 991px) {
  .service1-area .service1-inner-area {
    position: inherit;
    top: 0;
  }
}
.service1-area .service1-wrapper {
  margin-right: 0;
  margin-left: 0;
}
.service2-area {
  padding: 157px 0 74px;
  position: relative;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .service2-area {
    padding: 147px 0 63px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .service2-area {
    padding: 137px 0 27px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .service2-area {
    padding: 0 0 19px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .service2-area {
    padding: 0 0 23px;
  }
}
@media only screen and (max-width: 320px) {
  .service2-area {
    padding: 0 0 13px;
  }
}
.search-area {
  position: absolute;
  top: -60px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 9;
}
@media only screen and (max-width: 767px) {
  .search-area {
    position: relative;
    top: 15px;
    margin-bottom: 80px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .search-area {
    margin-bottom: 70px;
  }
}
@media only screen and (max-width: 320px) {
  .search-area {
    margin-bottom: 60px;
  }
}
.search-area .search-inner {
  margin-left: 0;
  margin-right: 0;
  padding: 35px 20px;
  background: #002147;
  box-shadow: 0px 5px 11px -5px rgba(0, 0, 0, 0.89);
}
.search-area .search-inner .search-title {
  margin-top: 10px;
  text-align: left;
}
@media only screen and (max-width: 767px) {
  .search-area .search-inner .search-title {
    text-align: center;
    margin-bottom: 10px;
  }
}
.search-area .search-inner .search-title span {
  color: #ffffff;
  font-size: 18px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .search-area .search-inner .search-title span {
    font-size: 16px;
  }
}
.search-area .search-inner .search-title span i {
  margin-right: 10px;
  color: #ffffff;
}
@media only screen and (max-width: 767px) {
  .search-area .search-inner .search-input {
    margin-bottom: 10px;
  }
}
.search-area .search-inner .search-input input {
  height: 52px;
  width: 100%;
  padding: 15px;
}
.search-area .search-inner .search-submit {
  text-align: right;
}
@media only screen and (max-width: 767px) {
  .search-area .search-inner .search-submit {
    text-align: center;
  }
}
/*=======================================================================
[07] Students Say Area
=========================================================================*/
.students-say-area {
  padding: 88px 0 90px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .students-say-area {
    padding: 78px 0 80px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .students-say-area {
    padding: 68px 0 70px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .students-say-area {
    padding: 58px 0 60px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .students-say-area {
    padding: 48px 0 50px;
  }
}
@media only screen and (max-width: 320px) {
  .students-say-area {
    padding: 38px 0 40px;
  }
}
.students-say-area .single-item-wrapper {
  position: relative;
}
.students-say-area .profile-img-wrapper {
  position: absolute;
  z-index: 1;
  right: 15px;
  top: 50px;
}
.students-say-area .profile-img-wrapper a img {
  border: 10px solid #ffffff;
  width: auto;
  margin: 0 auto;
}
.students-say-area .tlp-tm-content-wrapper .item-title {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 500;
}
.students-say-area .tlp-tm-content-wrapper .item-title a {
  color: #002147;
  transition: all 0.5s ease-out;
}
.students-say-area .tlp-tm-content-wrapper .item-title a:hover {
  color: #fdc800;
}
.students-say-area .tlp-tm-content-wrapper .item-designation {
  margin-bottom: 5px;
  display: block;
  color: #646464;
}
.students-say-area .item-content {
  position: relative;
  padding: 30px 130px 30px 30px;
  background: #f8f8f8;
  color: #646464;
}
.students-say-area .item-content:after {
  content: ' ';
  position: absolute;
  width: 0;
  height: 0;
  left: 38px;
  top: -30px;
  border: 15px solid;
  border-color: transparent transparent #f8f8f8  #f8f8f8;
}
.students-say-area .rating-wrapper {
  margin-bottom: 40px;
}
.students-say-area .rating-wrapper li {
  display: inline;
  color: #ffca28;
}
.students-say-area .owl-controls .owl-nav {
  margin-top: 0;
}
.students-say-area .owl-controls .owl-dots {
  margin-top: 40px;
}
.students-say-area .owl-controls .owl-dots .owl-dot span {
  margin: 3px;
  width: 20px;
  height: 8px;
  border-radius: 5px;
  background: #dedede;
  display: block;
  transition: all 0.5s ease-out;
}
.students-say-area .owl-controls .owl-dots .owl-dot:hover span {
  background: #002147;
}
.students-say-area .owl-controls .owl-dots .active span {
  background: #002147;
}
/*=======================================================================
[08] Students Join Area
=========================================================================*/
.students-join1-area {
  background: #fdc800;
  padding: 100px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .students-join1-area {
    padding: 90px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .students-join1-area {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .students-join1-area {
    padding: 70px 0;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .students-join1-area {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 320px) {
  .students-join1-area {
    padding: 50px 0;
  }
}
.students-join1-wrapper {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  box-shadow: 0px 0px 12px -1px rgba(0, 0, 0, 0.53);
}
@media only screen and (max-width: 991px) {
  .students-join1-wrapper {
    display: block;
  }
}
.students-join1-wrapper .students-join1-left {
  -moz-flex: 5;
  flex: 5 1;
}
.students-join1-wrapper .students-join1-right {
  padding-top: 70px;
  -moz-flex: 2;
  flex: 2 1;
  text-align: center;
  background: #002147;
}
@media only screen and (max-width: 991px) {
  .students-join1-wrapper .students-join1-right {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.students-join1-wrapper .students-join1-right h2 {
  font-size: 36px;
  font-weight: 400;
  margin-bottom: 30px;
  line-height: 1.2;
  color: #ffffff;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .students-join1-wrapper .students-join1-right h2 {
    font-size: 32px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .students-join1-wrapper .students-join1-right h2 {
    font-size: 30px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .students-join1-wrapper .students-join1-right h2 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 479px) {
  .students-join1-wrapper .students-join1-right h2 {
    font-size: 24px;
  }
}
.students-join1-wrapper .students-join1-right h2 span {
  font-weight: 700;
}
.students-join2-area {
  background: #f5f5f5;
  padding: 100px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .students-join2-area {
    padding: 90px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .students-join2-area {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .students-join2-area {
    padding: 70px 0;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .students-join2-area {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 320px) {
  .students-join2-area {
    padding: 50px 0;
  }
}
.students-join2-wrapper {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  box-shadow: 0px 0px 12px -1px rgba(0, 0, 0, 0.53);
}
@media only screen and (max-width: 991px) {
  .students-join2-wrapper {
    display: block;
  }
}
.students-join2-wrapper .students-join2-left {
  -moz-flex: 5;
  flex: 5 1;
}
.students-join2-wrapper .students-join2-right {
  padding-top: 70px;
  -moz-flex: 2;
  flex: 2 1;
  text-align: center;
  background: #fdc800;
}
@media only screen and (max-width: 991px) {
  .students-join2-wrapper .students-join2-right {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.students-join2-wrapper .students-join2-right h2 {
  font-size: 36px;
  font-weight: 400;
  margin-bottom: 30px;
  line-height: 1.2;
  color: #ffffff;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .students-join2-wrapper .students-join2-right h2 {
    font-size: 32px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .students-join2-wrapper .students-join2-right h2 {
    font-size: 30px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .students-join2-wrapper .students-join2-right h2 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 479px) {
  .students-join2-wrapper .students-join2-right h2 {
    font-size: 24px;
  }
}
.students-join2-wrapper .students-join2-right h2 span {
  font-weight: 700;
  color: #002147;
}
.students-join3-area {
  background: #f5f5f5;
  padding: 100px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .students-join3-area {
    padding: 90px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .students-join3-area {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .students-join3-area {
    padding: 70px 0;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .students-join3-area {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 320px) {
  .students-join3-area {
    padding: 50px 0;
  }
}
.students-join3-wrapper {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  box-shadow: 0px 0px 12px -1px rgba(0, 0, 0, 0.53);
}
@media only screen and (max-width: 991px) {
  .students-join3-wrapper {
    display: block;
  }
}
.students-join3-wrapper .students-join3-left {
  -moz-flex: 5;
  flex: 5 1;
}
.students-join3-wrapper .students-join3-right {
  padding-top: 70px;
  -moz-flex: 2;
  flex: 2 1;
  text-align: center;
  background: #002147;
}
@media only screen and (max-width: 991px) {
  .students-join3-wrapper .students-join3-right {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.students-join3-wrapper .students-join3-right h2 {
  font-size: 36px;
  font-weight: 400;
  margin-bottom: 30px;
  line-height: 1.2;
  color: #ffffff;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .students-join3-wrapper .students-join3-right h2 {
    font-size: 32px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .students-join3-wrapper .students-join3-right h2 {
    font-size: 30px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .students-join3-wrapper .students-join3-right h2 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 479px) {
  .students-join3-wrapper .students-join3-right h2 {
    font-size: 24px;
  }
}
.students-join3-wrapper .students-join3-right h2 span {
  font-weight: 700;
  color: #fdc800;
}
/*=======================================================================
[09] Featured Area
=========================================================================*/
.featured-box {
  overflow: hidden;
  background: #ffffff;
  margin-bottom: 30px;
}
.featured-box .featured-img-holder {
  text-align: center;
  position: relative;
  overflow: hidden;
}
.featured-box .featured-img-holder:before {
  background-color: rgba(253, 200, 0, 0.8);
  z-index: 1;
}
.featured-box .featured-img-holder a {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  line-height: 54px;
  background-color: rgba(0, 33, 71, 0.9);
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 2;
  opacity: 0;
  transform: scale(1) translateX(-400px);
  transition: all 0.8s ease-out;
}
.featured-box .featured-img-holder a i {
  color: #fdc800;
  font-size: 20px;
  transition: all 0.3s ease-out;
}
.featured-box .featured-img-holder a:hover i {
  color: #ffffff;
}
.featured-box .featured-img-holder:hover a {
  opacity: 1;
  transform: scale(1) translateX(0px);
  transition: all 0.75s ease-out;
}
.featured-box .featured-content-holder {
  padding: 10px 10px 5px 15px;
}
.featured-box .featured-content-holder h3 {
  font-size: 22px;
  margin-bottom: 5px;
}
.featured-box .featured-content-holder h3 a {
  color: #002147;
  transition: all 0.3s ease-out;
}
.featured-box .featured-content-holder h3 a:hover {
  color: #fdc800;
}
.featured-box .featured-content-holder p {
  margin-bottom: 10px;
}
.featured-box2 {
  width: 100%;
  background: #f5f5f5;
  padding: 25px 30px 25px 30px;
  transition: all 0.3s ease-out;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .featured-box2 {
    text-align: center;
    padding: 25px 10px 15px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .featured-box2 {
    height: 198px;
  }
}
@media only screen and (max-width: 479px) {
  .featured-box2 {
    text-align: center;
    padding: 15px 10px 5px;
  }
}
@media only screen and (max-width: 767px) {
  .featured-box2 {
    margin-bottom: 15px;
  }
}
.featured-box2 i {
  float: left;
  margin-right: 20px;
  text-align: center;
  height: 60px;
  width: 60px;
  line-height: 58px;
  border-radius: 50%;
  background: #fdc800;
  font-size: 24px;
  color: #ffffff;
  transition: all 0.3s ease-out;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .featured-box2 i {
    float: none;
    margin-right: 0;
  }
}
@media only screen and (max-width: 479px) {
  .featured-box2 i {
    float: none;
    margin-right: 0;
  }
}
.featured-box2 h3 {
  margin-top: 12px;
  font-size: 22px;
  font-weight: 500;
}
.featured-box2 h3 a {
  color: #002147;
  transition: all 0.3s ease-out;
}
.featured-box2 h3 a:hover {
  color: #ffffff !important;
}
.featured-box2:before {
  background: #002147;
  transition: all 0.3s ease-out;
}
.featured-box2:hover i {
  background: #ffffff;
  color: #002147;
  transition: all 0.3s ease-out;
}
.featured-box2:hover h3 a {
  color: #fdc800;
  transition: all 0.3s ease-out;
}
.featured-area {
  padding: 88px 0 100px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .featured-area {
    padding: 78px 0 90px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .featured-area {
    padding: 68px 0 80px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .featured-area {
    padding: 58px 0 70px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .featured-area {
    padding: 48px 0 60px;
  }
}
@media only screen and (max-width: 320px) {
  .featured-area {
    padding: 38px 0 50px;
  }
}
.featured-wrapper > div:first-child .featured-img-holder a {
  top: 50%;
  transition: all 0.8s ease-out;
}
.featured-wrapper > div:first-child .featured-img-holder:hover {
  transition: all 1.2s ease-out;
}
.featured-wrapper > div:first-child .featured-content-holder {
  padding: 30px 30px 18px 40px;
}
.featured-wrapper > div:first-child .featured-content-holder h3 {
  margin-bottom: 10px;
}
.view-all-btn-area {
  text-align: center;
  margin-top: 30px;
}
/*=======================================================================
[10] Counter Area
=========================================================================*/
.counter-area {
  padding: 130px 0 135px;
  text-align: center;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .counter-area {
    padding: 120px 0 125px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .counter-area {
    padding: 110px 0 115px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .counter-area {
    padding: 100px 0 105px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .counter-area {
    padding: 90px 0 95px;
  }
}
@media only screen and (max-width: 320px) {
  .counter-area {
    padding: 80px 0 85px;
  }
}
.counter-area .counter1-box {
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.counter-area .counter1-box h2 {
  color: #ffffff;
  font-size: 72px;
  -moz-flex: 1;
  flex: 1 1;
}
@media (min-width: 480px) and (max-width: 767px) {
  .counter-area .counter1-box h2 {
    font-size: 56px;
  }
}
@media only screen and (max-width: 479px) {
  .counter-area .counter1-box h2 {
    font-size: 42px;
  }
}
.counter-area .counter1-box p {
  color: #ffffff;
  font-size: 18px;
  padding-top: 25px;
  padding-left: 15px;
  -moz-flex: 2;
  flex: 2 1;
  text-transform: uppercase;
  text-align: left;
}
/*=======================================================================
[11] Countdown Area
=========================================================================*/
.countdown-area {
  padding: 135px 0 145px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .countdown-area {
    padding: 125px 0 135px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .countdown-area {
    padding: 115px 0 125px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .countdown-area {
    padding: 105px 0 115px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .countdown-area {
    padding: 95px 0 105px;
  }
}
@media only screen and (max-width: 320px) {
  .countdown-area {
    padding: 85px 0 95px;
  }
}
@media only screen and (max-width: 1199px) {
  .countdown-area .countdown-content {
    text-align: center;
    margin-bottom: 20px;
  }
}
.countdown-area .countdown-content h2 {
  color: #ffffff;
  font-size: 60px;
  margin-bottom: 0;
  line-height: 1.2;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .countdown-area .countdown-content h2 {
    font-size: 54px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .countdown-area .countdown-content h2 {
    font-size: 48px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .countdown-area .countdown-content h2 {
    font-size: 42px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .countdown-area .countdown-content h2 {
    font-size: 36px;
  }
}
@media only screen and (max-width: 320px) {
  .countdown-area .countdown-content h2 {
    font-size: 32px;
  }
}
.countdown-area .countdown-content h3 {
  color: #ffffff;
  font-size: 20px;
  margin-bottom: 40px;
}
.countdown-area #countdown {
  margin-top: 40px;
  text-align: right;
}
@media only screen and (max-width: 1199px) {
  .countdown-area #countdown {
    text-align: center;
  }
}
.countdown-area #countdown .countdown-section {
  position: relative;
  padding-top: 7px;
  display: inline-block;
  text-align: center;
  margin-right: 80px;
}
@media only screen and (max-width: 479px) {
  .countdown-area #countdown .countdown-section {
    margin-right: 15px;
  }
}
.countdown-area #countdown .countdown-section:before {
  /* content: url("img/countdown.png"); */
  position: absolute;
  top: 15px;
  right: -45px;
  z-index: 2;
}
@media only screen and (max-width: 479px) {
  .countdown-area #countdown .countdown-section:before {
    display: none;
  }
}
.countdown-area #countdown .countdown-section:last-child {
  margin-right: 0;
}
.countdown-area #countdown .countdown-section:last-child:before {
  display: none;
}
.countdown-area #countdown .countdown-section h3 {
  font-size: 72px;
  color: #fdc800;
  margin-bottom: 0;
  font-weight: 700;
  line-height: 1;
}
@media only screen and (max-width: 479px) {
  .countdown-area #countdown .countdown-section h3 {
    font-size: 42px;
  }
}
.countdown-area #countdown .countdown-section h3 span {
  color: #ffffff;
  font-size: 80px;
  margin-left: 10px;
}
@media only screen and (max-width: 479px) {
  .countdown-area #countdown .countdown-section h3 span {
    font-size: 20px;
  }
}
.countdown-area #countdown .countdown-section p {
  color: #e6e6e6;
  text-transform: uppercase;
}
/*=======================================================================
[12] Video Area
=========================================================================*/
.video-area {
  padding: 108px 0 120px;
  position: relative;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .video-area {
    padding: 88px 0 100px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .video-area {
    padding: 78px 0 90px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .video-area {
    padding: 58px 0 70px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .video-area {
    padding: 48px 0 60px;
  }
}
@media only screen and (max-width: 320px) {
  .video-area {
    padding: 38px 0 50px;
  }
}
.video-area2 {
  padding: 178px 0;
  position: relative;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .video-area2 {
    padding: 158px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .video-area2 {
    padding: 148px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .video-area2 {
    padding: 128px 0;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .video-area2 {
    padding: 118px 0;
  }
}
@media only screen and (max-width: 320px) {
  .video-area2 {
    padding: 108px 0;
  }
}
.video-content {
  position: relative;
  text-align: center;
}
.video-content .video-title {
  letter-spacing: 1px;
  font-size: 36px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 10px;
}
@media only screen and (max-width: 480px) {
  .video-content .video-title {
    font-size: 30px;
  }
}
.video-content .video-sub-title {
  color: #a3bed5;
  text-align: center;
  margin: 0 auto;
  padding-bottom: 35px;
}
/*=======================================================================
[13] Lecturers Area
=========================================================================*/
.lecturers-area {
  padding: 88px 0 100px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .lecturers-area {
    padding: 78px 0 90px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .lecturers-area {
    padding: 68px 0 80px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .lecturers-area {
    padding: 58px 0 70px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .lecturers-area {
    padding: 48px 0 50px;
  }
}
@media only screen and (max-width: 320px) {
  .lecturers-area {
    padding: 38px 0 40px;
  }
}
.lecturers1-item-wrapper {
  text-align: center;
}
.lecturers-img-wrapper {
  overflow: hidden;
  margin-bottom: 20px;
}
.lecturers-img-wrapper img {
  width: 100%;
  transition: all 1s ease-out;
}
.lecturers-img-wrapper:hover img {
  transform: scale(1.2);
}
.lecturers-content-wrapper {
  text-align: center;
}
.lecturers-content-wrapper .item-title {
  font-size: 22px;
  font-weight: 500;
  margin-bottom: 5px;
  text-transform: capitalize;
}
.lecturers-content-wrapper .item-title a {
  color: #002147;
  transition: all 0.5s ease-out;
}
.lecturers-content-wrapper .item-title a:hover {
  color: #fdc800;
}
.lecturers-content-wrapper .item-designation {
  margin-bottom: 20px;
  display: block;
}
.lecturers-content-wrapper .lecturers-social li {
  display: inline-block;
}
.lecturers-content-wrapper .lecturers-social li a {
  height: 30px;
  width: 35px;
  background: #fdc800;
  display: block;
  line-height: 30px;
  transition: all 0.5s ease-out;
}
.lecturers-content-wrapper .lecturers-social li a i {
  color: #ffffff;
  font-size: 14px;
}
.lecturers-content-wrapper .lecturers-social li a:hover {
  background: #002147;
}
.lecturers-item-wrapper {
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
}
.lecturers-item-wrapper:before {
  /* background-image: url(img/team/back.png); */
  background-repeat: repeat-x;
  background-position: bottom;
  width: 100%;
  position: absolute;
  content: "";
  z-index: 1;
  top: 0;
  height: 100%;
  transition: all 0.5s ease-out;
}
.lecturers-item-wrapper:after {
  opacity: 0;
  width: 100%;
  position: absolute;
  content: "";
  z-index: 1;
  top: 0;
  height: 100%;
  background-color: rgba(0, 33, 71, 0.8);
  transform: translateY(300px);
  transition: all 0.5s ease-out;
}
.lecturers-item-wrapper a img {
  width: 100%;
}
.lecturers-item-wrapper .lecturers-content-wrapper {
  padding: 0 30px;
  position: absolute;
  top: 258px;
  z-index: 2;
  transition: all 0.5s ease-out;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .lecturers-item-wrapper .lecturers-content-wrapper {
    padding: 0 15px;
    top: 225px;
  }
}
@media only screen and (max-width: 479px) {
  .lecturers-item-wrapper .lecturers-content-wrapper {
    top: 330px;
  }
}
@media only screen and (max-width: 425px) {
  .lecturers-item-wrapper .lecturers-content-wrapper {
    top: 276px;
  }
}
@media only screen and (max-width: 375px) {
  .lecturers-item-wrapper .lecturers-content-wrapper {
    top: 227px;
  }
}
@media only screen and (max-width: 320px) {
  .lecturers-item-wrapper .lecturers-content-wrapper {
    top: 190px;
  }
}
.lecturers-item-wrapper .lecturers-content-wrapper h3 {
  font-weight: 500;
  margin-bottom: 0;
  font-size: 22px;
}
.lecturers-item-wrapper .lecturers-content-wrapper h3 a {
  color: #fdc800;
  transition: all 0.5s ease-out;
}
.lecturers-item-wrapper .lecturers-content-wrapper h3 a:hover {
  color: #ffffff;
  transition: all 0.5s ease-out;
}
.lecturers-item-wrapper .lecturers-content-wrapper span {
  color: #ffffff;
  margin-bottom: 42px;
  display: block;
  font-size: 18px;
  transition: all 0.5s ease-out;
}
.lecturers-item-wrapper .lecturers-content-wrapper p {
  margin-bottom: 40px;
  color: #ffffff;
}
.lecturers-item-wrapper .lecturers-content-wrapper .lecturers-social li {
  display: inline-block;
  margin: 0 2px;
}
.lecturers-item-wrapper .lecturers-content-wrapper .lecturers-social li a {
  height: 40px;
  width: 45px;
  background: transparent;
  display: block;
  line-height: 40px;
  border: 1px solid #ffffff;
  transition: all 0.5s ease-out;
}
.lecturers-item-wrapper .lecturers-content-wrapper .lecturers-social li a i {
  color: #fdc800;
  font-size: 18px;
  transition: all 0.5s ease-out;
}
.lecturers-item-wrapper .lecturers-content-wrapper .lecturers-social li a:hover {
  border: 1px solid #fdc800;
  background: #fdc800;
}
.lecturers-item-wrapper .lecturers-content-wrapper .lecturers-social li a:hover i {
  color: #ffffff;
}
.lecturers-item-wrapper:hover:after {
  opacity: 1;
  transform: translateY(0);
  transition: all 0.5s ease-out;
}
.lecturers-item-wrapper:hover:before {
  opacity: 0;
  transition: all 3s ease-out;
}
.lecturers-item-wrapper:hover .lecturers-content-wrapper {
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transition: all 0.5s ease-out;
}
.lecturers-item-wrapper:hover .lecturers-content-wrapper span {
  margin-bottom: 20px;
  transition: all 0.5s ease-out;
}
.lecturers-page-area {
  padding: 100px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .lecturers-page-area {
    padding: 90px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .lecturers-page-area {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .lecturers-page-area {
    padding: 70px 0;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .lecturers-page-area {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 320px) {
  .lecturers-page-area {
    padding: 50px 0;
  }
}
.lecturers-contact-info {
  text-align: center;
}
.lecturers-contact-info img {
  margin-bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .lecturers-contact-info img {
    margin: 0 auto 30px;
  }
}
.lecturers-contact-info h2 {
  font-size: 30px;
  color: #002147;
  margin-bottom: 5px;
  font-weight: 500;
}
.lecturers-contact-info h3 {
  color: #909090;
  font-size: 16px;
  margin-bottom: 25px;
}
.lecturers-contact-info .lecturers-social2 li {
  display: inline-block;
  margin: 0 2px;
}
.lecturers-contact-info .lecturers-social2 li a {
  height: 40px;
  width: 45px;
  background: #fdc800;
  display: block;
  line-height: 40px;
  transition: all 0.5s ease-out;
}
.lecturers-contact-info .lecturers-social2 li a i {
  color: #ffffff;
  font-size: 14px;
}
.lecturers-contact-info .lecturers-social2 li a:hover {
  background: #002147;
}
.lecturers-contact-info .lecturers-contact {
  margin-top: 40px;
}
@media only screen and (max-width: 767px) {
  .lecturers-contact-info .lecturers-contact {
    margin-bottom: 30px;
  }
}
.lecturers-contact-info .lecturers-contact li {
  padding: 25px 0 30px;
  border: 1px solid #e6e6e6;
  font-size: 18px;
  color: #002147;
  margin-bottom: 20px;
}
.lecturers-contact-info .lecturers-contact li i {
  color: #fdc800;
  position: relative;
  top: 5px;
  font-size: 30px;
  margin-right: 15px;
}
.lecturers-contact-info .lecturers-contact li:last-child {
  margin-bottom: 0;
}
ul.course-feature2 {
  margin-bottom: 30px;
}
ul.course-feature2:after {
  clear: both;
  content: "";
  display: block;
}
ul.course-feature2 li {
  width: 49%;
  box-sizing: border-box;
  float: left;
  padding-left: 30px;
  position: relative;
  margin-bottom: 12px;
  margin-right: 5px;
  color: #444444;
}
@media only screen and (max-width: 991px) {
  ul.course-feature2 li {
    width: 100%;
  }
}
ul.course-feature2 li:before {
  content: "\f058";
  position: absolute;
  font-family: FontAwesome;
  left: 0;
  top: 0;
  font-size: 20px;
  border-radius: 50%;
  z-index: 1;
  line-height: 1.3;
  color: #fdc800;
}
.lecturers-page1-area {
  padding: 100px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .lecturers-page1-area {
    padding: 90px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .lecturers-page1-area {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .lecturers-page1-area {
    padding: 70px 0;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .lecturers-page1-area {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 320px) {
  .lecturers-page1-area {
    padding: 50px 0;
  }
}
.lecturers-page1-area .single-item {
  margin-bottom: 30px;
}
.lecturers-page2-area {
  padding: 100px 0 70px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .lecturers-page2-area {
    padding: 90px 0 60px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .lecturers-page2-area {
    padding: 80px 0 50px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .lecturers-page2-area {
    padding: 70px 0 40px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .lecturers-page2-area {
    padding: 60px 0 30px;
  }
}
@media only screen and (max-width: 320px) {
  .lecturers-page2-area {
    padding: 50px 0 20px;
  }
}
/*=======================================================================
[14] Publications Area
=========================================================================*/
.publications-area {
  padding: 88px 0 72px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .publications-area {
    padding: 78px 0 62px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .publications-area {
    padding: 68px 0 52px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .publications-area {
    padding: 58px 0 42px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .publications-area {
    padding: 50px 0 23px;
  }
}
@media only screen and (max-width: 320px) {
  .publications-area {
    padding: 38px 0 12px;
  }
}
.publications-area .single-item-wrapper {
  margin-bottom: 20px;
}
.publications-area .single-item-wrapper:hover .publications-img-wrapper:before {
  opacity: 1;
  transition: all 0.55s ease-out;
}
.publications-area .single-item-wrapper:hover .buy-now-btn-area {
  transform: skewX(0);
  opacity: 1;
  transition: all 0.7s ease-out;
}
.publications-img-wrapper {
  overflow: hidden;
  position: relative;
  margin-bottom: 20px;
}
.publications-img-wrapper:before {
  opacity: 0;
  position: absolute;
  background-color: rgba(0, 33, 71, 0.8);
  z-index: 2;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  transition: all 0.75s ease-out;
}
.publications-img-wrapper img {
  width: 100%;
}
.publications-content-wrapper {
  text-align: center;
}
.publications-content-wrapper .item-title {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 5px;
}
.publications-content-wrapper .item-title a {
  color: #002147;
  transition: all 0.5s ease-out;
}
.publications-content-wrapper .item-title a:hover {
  color: #fdc800;
  transition: all 0.5s ease-out;
}
.publications-content-wrapper .item-price {
  font-size: 20px;
  margin-bottom: 20px;
  display: block;
  font-weight: 500;
  color: #fdc800;
}
.buy-now-btn-area {
  position: absolute;
  top: 30%;
  z-index: 3;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  opacity: 0;
  transform: skewX(30deg);
  transition: all 0.3s ease-out;
}
/*=======================================================================
[15] Courses Area
=========================================================================*/
.courses-page-top-area {
  border: 1px solid #e0e0e0;
  background: #ffffff;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.courses-page-top-area .courses-page-top-left {
  padding: 15px 0 0 30px;
  -moz-flex: 2;
  flex: 2 1;
}
@media only screen and (max-width: 380px) {
  .courses-page-top-area .courses-page-top-left {
    padding: 18px 0 0 10px;
  }
}
.courses-page-top-area .courses-page-top-left p {
  font-size: 16px;
  color: #002147;
}
@media only screen and (max-width: 380px) {
  .courses-page-top-area .courses-page-top-left p {
    font-size: 14px;
  }
}
.courses-page-top-area .courses-page-top-right {
  -moz-flex: 1;
  flex: 1 1;
  text-align: right;
}
.courses-page-top-area .courses-page-top-right ul {
  margin-top: 10px;
  margin-right: 10px;
}
.courses-page-top-area .courses-page-top-right ul > li {
  display: inline-block;
  padding: 8px;
}
.courses-page-top-area .courses-page-top-right ul > li:hover a i {
  color: #fdc800;
}
.courses-page-top-area .courses-page-top-right ul > li > a > i {
  color: #b9b9b9;
}
.courses-page-top-area .courses-page-top-right ul > .active i {
  color: #fdc800;
}
.tab-content {
  margin-top: 30px;
}
.courses-box1 .single-item-wrapper {
  margin-bottom: 30px;
  overflow: hidden;
  background: #ffffff;
  transition: all 0.5s ease-out;
}
.courses-box1 .single-item-wrapper .courses-img-wrapper {
  position: relative;
}
.courses-box1 .single-item-wrapper .courses-img-wrapper img {
  width: 100%;
}
.courses-box1 .single-item-wrapper .courses-img-wrapper:before {
  background-color: rgba(253, 200, 0, 0.8);
  z-index: 1;
}
.courses-box1 .single-item-wrapper .courses-img-wrapper:hover a {
  opacity: 1;
  transition: all 0.5s ease-out;
}
.courses-box1 .single-item-wrapper .courses-img-wrapper a {
  opacity: 0;
  text-align: center;
  position: absolute;
  top: 50%;
  z-index: 3;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  line-height: 50px;
  color: #ffffff;
  display: block;
  background: #002147;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transition: all 0.5s ease-out;
}
.courses-box1 .single-item-wrapper .courses-content-wrapper {
  padding: 20px 15px;
}
.courses-box1 .single-item-wrapper .courses-content-wrapper .item-title {
  font-size: 22px;
  margin-bottom: 10px;
}
.courses-box1 .single-item-wrapper .courses-content-wrapper .item-title a {
  color: #002147;
  transition: all 0.5s ease-out;
}
.courses-box1 .single-item-wrapper .courses-content-wrapper .item-title a:hover {
  color: #fdc800;
}
.courses-box1 .single-item-wrapper .courses-content-wrapper .courses-info li {
  line-height: 1.3;
  padding-right: 7px;
  margin-right: 2px;
  border-right: 1px solid #909090;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
}
.courses-box1 .single-item-wrapper .courses-content-wrapper .courses-info li:last-child {
  border-right: none;
  margin-right: 0;
  padding-right: 0;
}
.courses-box2 .single-item-wrapper {
  margin-bottom: 30px;
  background: #ffffff;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  transition: all 0.5s ease-out;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .courses-box2 .single-item-wrapper {
    display: block;
  }
}
@media only screen and (max-width: 480px) {
  .courses-box2 .single-item-wrapper {
    display: block;
  }
}
.courses-box2 .single-item-wrapper .courses-img-wrapper {
  -moz-flex: 9;
  flex: 9 1;
  position: relative;
}
.courses-box2 .single-item-wrapper .courses-img-wrapper:before {
  background-color: rgba(253, 200, 0, 0.8);
  z-index: 2;
  transition: all 0.5s ease-out;
}
.courses-box2 .single-item-wrapper .courses-img-wrapper:hover a {
  opacity: 1;
  transition: all 0.5s ease-out;
}
.courses-box2 .single-item-wrapper .courses-img-wrapper a {
  opacity: 0;
  text-align: center;
  position: absolute;
  top: 50%;
  z-index: 3;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  line-height: 50px;
  color: #ffffff;
  display: block;
  background: #002147;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transition: all 0.5s ease-out;
}
.courses-box2 .single-item-wrapper .courses-content-wrapper {
  -moz-flex: 10;
  flex: 10 1;
  padding: 17px 15px 15px 28px;
}
.courses-box2 .single-item-wrapper .courses-content-wrapper .item-title {
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: 500;
}
.courses-box2 .single-item-wrapper .courses-content-wrapper .item-title a {
  color: #002147;
}
.courses-box2 .single-item-wrapper .courses-content-wrapper .courses-info li {
  line-height: 1.3;
  padding-right: 10px;
  margin-right: 8px;
  border-right: 1px solid #909090;
  display: inline-block;
  font-size: 13px;
  font-weight: 500;
}
@media only screen and (max-width: 479px) {
  .courses-box2 .single-item-wrapper .courses-content-wrapper .courses-info li {
    margin-right: 5px;
    padding-right: 7px;
  }
}
.courses-box2 .single-item-wrapper .courses-content-wrapper .courses-info li:last-child {
  border-right: none;
  margin-right: 0;
  padding-right: 0;
}
.courses-box2 .single-item-wrapper:hover {
  box-shadow: 0px 0px 20px 5px rgba(218, 218, 218, 0.75);
  transition: all 0.3s ease-out;
}
.courses-box3 .single-item-wrapper {
  margin-bottom: 30px;
  background: #ffffff;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 991px) {
  .courses-box3 .single-item-wrapper {
    display: block;
  }
}
.courses-box3 .single-item-wrapper .courses-img-wrapper {
  position: relative;
  -moz-flex: 1;
  flex: 1 1;
}
.courses-box3 .single-item-wrapper .courses-img-wrapper:before {
  background-color: rgba(253, 200, 0, 0.8);
  z-index: 1;
}
.courses-box3 .single-item-wrapper .courses-img-wrapper:hover a {
  opacity: 1;
  transition: all 0.5s ease-out;
}
.courses-box3 .single-item-wrapper .courses-img-wrapper a {
  opacity: 0;
  text-align: center;
  position: absolute;
  top: 50%;
  z-index: 3;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  line-height: 50px;
  color: #ffffff;
  display: block;
  background: #002147;
  left: 0;
  right: 0;
  margin: 0 auto;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transition: all 0.5s ease-out;
}
.courses-box3 .single-item-wrapper .courses-content-wrapper {
  position: relative;
  -moz-flex: 2;
  flex: 2 1;
  padding: 18px 15px 10px 30px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .courses-box3 .single-item-wrapper .courses-content-wrapper {
    padding: 12px 15px 10px 30px;
  }
}
@media only screen and (max-width: 479px) {
  .courses-box3 .single-item-wrapper .courses-content-wrapper {
    padding: 12px 15px 10px;
  }
}
.courses-box3 .single-item-wrapper .courses-content-wrapper .item-title {
  font-size: 20px;
  margin-bottom: 12px;
  font-weight: 500;
}
.courses-box3 .single-item-wrapper .courses-content-wrapper .item-title a {
  color: #002147;
  transition: all 0.5s ease-out;
}
.courses-box3 .single-item-wrapper .courses-content-wrapper .item-title a:hover {
  color: #fdc800;
}
.courses-box3 .single-item-wrapper .courses-content-wrapper .item-content {
  margin-bottom: 12px;
}
.courses-box3 .single-item-wrapper .courses-content-wrapper .courses-info li {
  line-height: 1.3;
  padding-right: 10px;
  margin-right: 8px;
  border-right: 1px solid #909090;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
}
.courses-box3 .single-item-wrapper .courses-content-wrapper .courses-info li:last-child {
  border-right: none;
  margin-right: 0;
  padding-right: 0;
}
.courses-box3 .single-item-wrapper .courses-content-wrapper .courses-fee {
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 75px;
  height: 35px;
  padding: 3px;
  font-weight: 500;
  text-align: center;
  font-size: 20px;
  background: #fdc800;
  color: #002147;
  z-index: 1;
}
@media only screen and (max-width: 479px) {
  .courses-box3 .single-item-wrapper .courses-content-wrapper .courses-fee {
    top: 15px;
    bottom: inherit;
    font-size: 18px;
    height: 30px;
  }
}
.courses-box3 .single-item-wrapper:hover {
  box-shadow: 0px 0px 20px 5px rgba(218, 218, 218, 0.75);
  transition: all 0.3s ease-out;
}
.courses1-area {
  padding: 88px 0 70px;
  background: #f5f5f5;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .courses1-area {
    padding: 78px 0 60px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .courses1-area {
    padding: 68px 0 50px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .courses1-area {
    padding: 58px 0 40px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .courses1-area {
    padding: 48px 0 20px;
  }
}
@media only screen and (max-width: 320px) {
  .courses1-area {
    padding: 38px 0 10px;
  }
}
.courses2-area {
  padding: 88px 0 100px;
  background: #f5f5f5;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .courses2-area {
    padding: 78px 0 90px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .courses2-area {
    padding: 68px 0 80px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .courses2-area {
    padding: 58px 0 70px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .courses2-area {
    padding: 48px 0 60px;
  }
}
@media only screen and (max-width: 320px) {
  .courses2-area {
    padding: 38px 0 50px;
  }
}
.courses-page-area1 {
  padding: 100px 0;
  background: #f5f5f5;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .courses-page-area1 {
    padding: 90px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .courses-page-area1 {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .courses-page-area1 {
    padding: 70px 0;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .courses-page-area1 {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 320px) {
  .courses-page-area1 {
    padding: 50px 0;
  }
}
@media only screen and (max-width: 767px) {
  .courses-page-area1 .courses-img-wrapper {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .courses-page-area1 .courses-img-wrapper img {
    width: 100%;
  }
}
.courses-page-area2 {
  padding: 100px 0 70px;
  background: #f5f5f5;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .courses-page-area2 {
    padding: 90px 0 60px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .courses-page-area2 {
    padding: 80px 0 50px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .courses-page-area2 {
    padding: 70px 0 40px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .courses-page-area2 {
    padding: 60px 0 30px;
  }
}
@media only screen and (max-width: 320px) {
  .courses-page-area2 {
    padding: 50px 0 20px;
  }
}
.courses-page-area3 {
  padding: 100px 0;
  background: #f5f5f5;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .courses-page-area3 {
    padding: 90px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .courses-page-area3 {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .courses-page-area3 {
    padding: 70px 0;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .courses-page-area3 {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 320px) {
  .courses-page-area3 {
    padding: 50px 0;
  }
}
.course-details-inner {
  padding: 30px 60px;
  background: #ffffff;
}
.course-details-inner ul.course-feature:after {
  clear: both;
  content: "";
  display: block;
}
.course-details-inner ul.course-feature li {
  width: 32%;
  box-sizing: border-box;
  float: left;
  padding-left: 30px;
  position: relative;
  margin-bottom: 10px;
  margin-right: 5px;
  color: #444444;
}
@media only screen and (max-width: 991px) {
  .course-details-inner ul.course-feature li {
    width: 100%;
  }
}
.course-details-inner ul.course-feature li:before {
  content: "\f058";
  position: absolute;
  font-family: FontAwesome;
  left: 0;
  top: 0;
  font-size: 20px;
  border-radius: 50%;
  z-index: 1;
  line-height: 1.3;
  color: #fdc800;
}
.course-details-comments {
  margin-bottom: 50px;
}
.course-details-comments .media {
  padding-bottom: 8px;
  margin-bottom: 35px;
  border-bottom: 1px solid #e3e3e3;
}
@media only screen and (max-width: 479px) {
  .course-details-comments .media {
    margin-bottom: 0;
  }
}
.course-details-comments .media:last-child {
  border-bottom: 0;
}
@media only screen and (max-width: 479px) {
  .course-details-comments .media a {
    float: none!important;
  }
}
.course-details-comments .media a img {
  transition: all 0.5s ease-out;
}
.course-details-comments .media a img:hover {
  opacity: 0.5;
  transition: all 0.5s ease-out;
}
.course-details-comments .media .media-body {
  position: relative;
  padding-left: 20px;
}
@media only screen and (max-width: 479px) {
  .course-details-comments .media .media-body {
    padding-left: 0;
    padding-right: 5px;
  }
}
.course-details-comments .media .media-body h3 {
  font-size: 16px;
  margin-bottom: 8px;
  font-weight: 500;
}
.course-details-comments .media .media-body h3 a {
  color: #002147;
  transition: all 0.5s ease-out;
}
.course-details-comments .media .media-body h3 a:hover {
  color: #fdc800;
}
.course-details-comments .media .media-body h4 {
  color: #ffffff;
  background: #002147;
  padding: 2px 10px;
  display: inline-block;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 500;
}
.course-details-comments .media .media-body p {
  color: #757575;
  text-align: justify;
}
.course-details-comments .media .media-body .replay-area {
  position: absolute;
  right: 0;
  top: 35px;
  z-index: 3;
}
@media only screen and (max-width: 479px) {
  .course-details-comments .media .media-body .replay-area {
    right: 10px;
  }
}
.course-details-comments .media .media-body .replay-area ul li {
  display: inline-block;
}
.course-details-comments .media .media-body .replay-area ul li i {
  color: #fdc800;
}
.leave-comments .form-group {
  margin-bottom: 20px;
}
.leave-comments .form-group .form-control {
  border-radius: 0;
  background: #f9f9f9;
  box-shadow: none;
  border: none;
}
.leave-comments .form-group input {
  height: 45px;
  color: #212121;
  font-weight: 500;
  border-right: 1px solid #e5e5e5!important;
  border-bottom: 1px solid #e5e5e5!important;
}
.leave-comments .form-group textarea {
  border-right: 1px solid #e5e5e5!important;
  border-bottom: 1px solid #e5e5e5!important;
}
.leave-comments .rate-wrapper {
  display: inline-block;
  margin: 5px 0;
}
.leave-comments .rate {
  float: left;
  color: #f5f5f5;
  cursor: pointer;
}
.leave-comments .rate:hover,
.leave-comments .rate.selected {
  color: #fdc800;
}
.leave-comments .rate-label {
  float: left;
  color: #002147;
  margin-right: 10px;
  margin-left: 0;
}
.leave-comments .rate-item {
  float: left;
  cursor: pointer;
  margin: 0px 5px 0px 5px;
}
.leave-comments .rate-item:hover ~ .rate-item,
.leave-comments .rate-item.active ~ .rate-item {
  color: #f5f5f5;
}
.courses-page-area4 {
  padding: 100px 0;
  background: #f5f5f5;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .courses-page-area4 {
    padding: 90px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .courses-page-area4 {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .courses-page-area4 {
    padding: 70px 0;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .courses-page-area4 {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 320px) {
  .courses-page-area4 {
    padding: 50px 0;
  }
}
.course-details-inner {
  padding: 20px 30px 30px;
  background: #ffffff;
}
@media only screen and (max-width: 479px) {
  .course-details-inner {
    padding: 20px 15px 30px;
  }
}
.course-details-inner .course-details-tab-area {
  margin: 30px 0 0;
  overflow: hidden;
}
.course-details-inner .course-details-tab-area ul.course-details-tab-btn {
  background: #f5f5f5;
}
.course-details-inner .course-details-tab-area ul.course-details-tab-btn > li {
  display: inline-block;
  margin: -1px;
  background: transparent;
  border-right: 1px solid #cdcdcd;
  transition: all 0.5s ease-out;
}
.course-details-inner .course-details-tab-area ul.course-details-tab-btn > li:last-child {
  border-right: 0;
}
@media only screen and (max-width: 479px) {
  .course-details-inner .course-details-tab-area ul.course-details-tab-btn > li {
    display: block!important;
    padding: 0;
  }
}
.course-details-inner .course-details-tab-area ul.course-details-tab-btn > li a {
  display: block;
  color: #002147;
  padding: 20px 30px;
  text-transform: capitalize;
  font-weight: 500;
  transition: all 0.5s ease-out;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .course-details-inner .course-details-tab-area ul.course-details-tab-btn > li a {
    padding: 20px 17px;
  }
}
@media only screen and (max-width: 479px) {
  .course-details-inner .course-details-tab-area ul.course-details-tab-btn > li a {
    display: block!important;
  }
}
.course-details-inner .course-details-tab-area ul.course-details-tab-btn > li:hover {
  background: #002147;
}
.course-details-inner .course-details-tab-area ul.course-details-tab-btn > li:hover a {
  color: #ffffff;
}
.course-details-inner .course-details-tab-area ul.course-details-tab-btn .active {
  background: #002147;
}
.course-details-inner .course-details-tab-area ul.course-details-tab-btn .active a {
  color: #ffffff;
  text-decoration: none;
}
.course-details-inner .course-details-tab-area .tab-content {
  padding: 35px 0 15px 0;
  margin-top: 0;
}
.course-details-inner .course-details-tab-area ul.course-feature:after {
  clear: both;
  content: "";
  display: block;
}
.course-details-inner .course-details-tab-area ul.course-feature li {
  width: 49%;
  box-sizing: border-box;
  float: left;
  padding-left: 30px;
  position: relative;
  margin-bottom: 10px;
  margin-right: 5px;
  color: #444444;
}
@media only screen and (max-width: 991px) {
  .course-details-inner .course-details-tab-area ul.course-feature li {
    width: 100%;
  }
}
.course-details-inner .course-details-tab-area ul.course-feature li:before {
  content: "\f058";
  position: absolute;
  font-family: FontAwesome;
  left: 0;
  top: 0;
  font-size: 20px;
  border-radius: 50%;
  z-index: 1;
  line-height: 1.3;
  color: #fdc800;
}
.course-details-inner .course-details-tab-area .course-details-skilled-lecturers ul li {
  margin-bottom: 10px;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
@media only screen and (max-width: 991px) {
  .course-details-inner .course-details-tab-area .course-details-skilled-lecturers ul li {
    display: block;
    text-align: center;
    clear: both;
    margin-bottom: 30px;
  }
}
.course-details-inner .course-details-tab-area .course-details-skilled-lecturers ul li:last-child {
  margin-bottom: 0;
}
.course-details-inner .course-details-tab-area .course-details-skilled-lecturers ul li .skilled-lecturers-img {
  -moz-flex: 3;
  flex: 3 1;
}
@media only screen and (max-width: 991px) {
  .course-details-inner .course-details-tab-area .course-details-skilled-lecturers ul li .skilled-lecturers-img img {
    margin: 0 auto 15px;
  }
}
.course-details-inner .course-details-tab-area .course-details-skilled-lecturers ul li .skilled-lecturers-content {
  -moz-flex: 6;
  flex: 6 1;
  margin-right: 10px;
}
.course-details-inner .course-details-tab-area .course-details-skilled-lecturers ul li .skilled-lecturers-content h4 {
  margin-bottom: 0;
  font-size: 18px;
}
.course-details-inner .course-details-tab-area .course-details-skilled-lecturers ul li .skilled-lecturers-content h4 a {
  color: #002147;
  transition: all 0.3s ease-out;
}
.course-details-inner .course-details-tab-area .course-details-skilled-lecturers ul li .skilled-lecturers-content h4 a:hover {
  color: #fdc800;
  transition: all 0.3s ease-out;
}
.course-details-inner .course-details-tab-area .course-details-skilled-lecturers ul li .skilled-lecturers-schedule {
  -moz-flex: 7;
  flex: 7 1;
}
@media only screen and (max-width: 991px) {
  .course-details-inner .course-details-tab-area .course-details-skilled-lecturers ul li .skilled-lecturers-schedule {
    float: left;
  }
}
@media only screen and (max-width: 479px) {
  .course-details-inner .course-details-tab-area .course-details-skilled-lecturers ul li .skilled-lecturers-schedule {
    float: inherit;
  }
}
.course-details-inner .course-details-tab-area .course-details-skilled-lecturers ul li .skilled-lecturers-schedule ul li {
  width: 100px;
  display: inline-block;
  border-right: 1px solid #cdcdcd;
  margin-right: 15px;
  height: 58px;
}
@media only screen and (max-width: 479px) {
  .course-details-inner .course-details-tab-area .course-details-skilled-lecturers ul li .skilled-lecturers-schedule ul li {
    margin-right: 10px;
  }
}
.course-details-inner .course-details-tab-area .course-details-skilled-lecturers ul li .skilled-lecturers-schedule ul li:last-child {
  border-right: none;
  margin-right: 0;
}
.course-details-inner .course-details-tab-area .course-details-skilled-lecturers ul li .skilled-lecturers-schedule ul li h4 {
  font-size: 16px;
  color: #002147;
  margin-bottom: 5px;
}
.course-details-inner .course-details-tab-area .course-details-skilled-lecturers ul li .skilled-lecturers-details {
  -moz-flex: 2;
  flex: 2 1;
}
.related-courses-title-area {
  margin: 30px 0 0 0;
  background: #ffffff;
}
.related-courses-title-area h3 {
  padding: 12px 20px;
  color: #002147;
  font-size: 22px;
  font-weight: 500;
  position: relative;
  text-transform: capitalize;
}
.related-courses-carousel .owl-controls .owl-nav {
  top: -74px;
  right: 25px;
}
.courses-page-area5 {
  padding: 100px 0 70px;
  background: #f5f5f5;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .courses-page-area5 {
    padding: 90px 0 60px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .courses-page-area5 {
    padding: 80px 0 50px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .courses-page-area5 {
    padding: 70px 0 69px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .courses-page-area5 {
    padding: 60px 0 59px;
  }
}
@media only screen and (max-width: 320px) {
  .courses-page-area5 {
    padding: 50px 0 49px;
  }
}
.learning-outcomes li {
  margin-bottom: 10px;
  position: relative;
  padding-left: 30px;
  color: #444444;
}
.learning-outcomes li:last-child {
  margin-bottom: 0;
}
.learning-outcomes li:before {
  content: "\f105";
  background: #002147;
  color: #ffffff;
  position: absolute;
  left: 0;
  top: 5px;
  font-size: 14px;
  border-radius: 50%;
  height: 15px;
  width: 15px;
  line-height: 14px;
  text-align: center;
  z-index: 1;
  font-family: FontAwesome;
}
.curriculum-wrapper .panel-default {
  margin-top: 0!important;
  border-radius: 0!important;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #e2e2e2;
  transition: all 0.3s ease-out;
}
.curriculum-wrapper .panel-default:last-child {
  border-bottom: 0;
}
.curriculum-wrapper .panel-default .panel-heading {
  padding: 0;
  background: transparent;
  transition: all 0.3s ease-out;
}
.curriculum-wrapper .panel-default .panel-heading .panel-title a {
  padding: 13px 15px 15px;
  display: block;
}
.curriculum-wrapper .panel-default .panel-heading .panel-title a ul li {
  border-top: none;
  display: inline-block;
}
.curriculum-wrapper .panel-default .panel-heading .panel-title a ul li:nth-child(1n) i {
  margin-right: 10px;
  color: #fdc800;
  transition: all 0.3s ease-out;
}
.curriculum-wrapper .panel-default .panel-heading .panel-title a ul li:nth-child(2n) {
  margin-right: 30px;
  color: #888888;
}
.curriculum-wrapper .panel-default .panel-heading .panel-title a ul li:nth-child(3n) {
  font-size: 16px;
  color: #002147;
  font-weight: 500;
  transition: all 0.3s ease-out;
}
.curriculum-wrapper .panel-default .panel-heading .panel-title a ul li:nth-child(4n) {
  position: absolute;
  right: 50px;
}
@media only screen and (max-width: 991px) {
  .curriculum-wrapper .panel-default .panel-heading .panel-title a ul li:nth-child(4n) {
    display: none;
  }
}
.curriculum-wrapper .panel-default .panel-heading .panel-title a ul li:nth-child(4n) i {
  margin-right: 10px;
  color: #888888;
  transition: all 0.3s ease-out;
}
.curriculum-wrapper .panel-default .panel-heading .panel-title a ul li:nth-child(4n) span {
  color: #002147;
  font-weight: 500;
  margin-left: 5px;
  transition: all 0.3s ease-out;
}
.curriculum-wrapper .panel-default .panel-heading .accordion-toggle {
  position: relative;
}
.curriculum-wrapper .panel-default .panel-heading .accordion-toggle:after {
  font-family: FontAwesome;
  content: "\f106";
  right: 15px;
  position: absolute;
  top: 15px;
  color: #828282;
  font-size: 18px;
  z-index: 1;
}
.curriculum-wrapper .panel-default .panel-heading .accordion-toggle.collapsed:after {
  content: "\f107";
  font-family: FontAwesome;
}
.curriculum-wrapper .panel-default .panel-collapse {
  background: #f5f5f5;
  transition: all 0.3s ease-out;
}
.curriculum-wrapper .panel-default .panel-body {
  text-align: justify;
  padding: 0 75px 20px 45px;
  border-top: 0!important;
  color: #444444;
  transition: all 0.3s ease-out;
}
@media only screen and (max-width: 991px) {
  .curriculum-wrapper .panel-default .panel-body {
    padding: 0 15px 15px;
  }
}
.curriculum-wrapper .panel-default .active {
  background: #f5f5f5;
  transition: all 0.3s ease-out;
}
.curriculum-wrapper .panel-default .active .panel-title a ul li:nth-child(1n) i {
  color: #002147;
  transition: all 0.3s ease-out;
}
.curriculum-wrapper .panel-default .active .panel-title a ul li:nth-child(2n) {
  color: #002147;
  transition: all 0.3s ease-out;
}
.curriculum-wrapper .panel-default .active .panel-title a ul li:nth-child(4n) i {
  color: #002147;
  transition: all 0.3s ease-out;
}
/*=======================================================================
[16] News and Event Area
=========================================================================*/
.news-event-area {
  padding: 88px 0 100px;
  background: #f5f5f5;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .news-event-area {
    padding: 78px 0 60px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .news-event-area {
    padding: 68px 0 50px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .news-event-area {
    padding: 58px 0 40px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .news-event-area {
    padding: 48px 0 30px;
  }
}
@media only screen and (max-width: 320px) {
  .news-event-area {
    padding: 38px 0 20px;
  }
}
@media only screen and (max-width: 1199px) {
  .news-inner-area {
    margin-bottom: 30px;
  }
}
.news-inner-area .news-wrapper {
  background: #ffffff;
  padding: 20px 20px 15px;
}
.news-inner-area .news-wrapper li {
  background: #ffffff;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 20px;
  padding-bottom: 20px;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 1199px) {
  .news-inner-area .news-wrapper li {
    display: block;
  }
}
.news-inner-area .news-wrapper li .news-img-holder {
  -moz-flex: 1;
  flex: 1 1;
}
@media only screen and (max-width: 1199px) {
  .news-inner-area .news-wrapper li .news-img-holder {
    margin-bottom: 15px;
  }
}
.news-inner-area .news-wrapper li .news-content-holder {
  -moz-flex: 2;
  flex: 2 1;
}
.news-inner-area .news-wrapper li .news-content-holder h3 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 0;
}
.news-inner-area .news-wrapper li .news-content-holder h3 a {
  color: #002147;
  transition: all 0.3s ease-out;
}
.news-inner-area .news-wrapper li .news-content-holder h3 a:hover {
  color: #fdc800;
  transition: all 0.3s ease-out;
}
.news-inner-area .news-wrapper li .news-content-holder .post-date {
  font-style: italic;
  font-size: 14px;
  margin: 5px 0;
  font-weight: 500;
  color: #fdc800;
}
.news-inner-area .news-wrapper li .news-content-holder p {
  margin-bottom: 0;
}
.news-inner-area .news-wrapper li:last-child {
  border-bottom: none;
  margin-bottom: 4px;
  padding-bottom: 0;
}
.news-inner-area .news-wrapper li:before {
  background: #fdc800;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 1199px) {
  .news-inner-area .news-wrapper-responsive li {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
  }
}
@media only screen and (max-width: 479px) {
  .news-inner-area .news-wrapper-responsive li {
    display: block;
  }
}
.news-inner-area .news-wrapper-responsive li .news-content-holder {
  -moz-flex: 2;
  flex: 2 1;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .news-inner-area .news-wrapper-responsive li .news-content-holder {
    -moz-flex: 4;
    flex: 4 1;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .news-inner-area .news-wrapper-responsive li .news-content-holder {
    -moz-flex: 3;
    flex: 3 1;
  }
}
.news-inner-area .news-btn-holder {
  margin-top: 45px;
  text-align: center;
}
@media only screen and (max-width: 1199px) {
  .event-inner-area {
    margin-bottom: 30px;
  }
}
.event-inner-area .event-wrapper > li {
  margin-bottom: 30px;
  padding: 20px;
  background: #ffffff;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
@media only screen and (max-width: 1199px) {
  .event-inner-area .event-wrapper > li {
    display: block;
  }
}
.event-inner-area .event-wrapper > li:last-child {
  margin-bottom: 0;
}
.event-inner-area .event-wrapper > li .event-calender-wrapper {
  -moz-flex: 1;
  flex: 1 1;
}
@media only screen and (max-width: 1199px) {
  .event-inner-area .event-wrapper > li .event-calender-wrapper {
    margin-bottom: 15px;
  }
}
.event-inner-area .event-wrapper > li .event-calender-holder {
  position: relative;
  padding-top: 20px;
  text-align: center;
  width: 100px;
  background: #fdc800;
}
.event-inner-area .event-wrapper > li .event-calender-holder:before {
  content: "\f111";
  position: absolute;
  left: 10px;
  top: 5px;
  color: #002147;
  font-size: 14px;
  font-family: FontAwesome;
}
.event-inner-area .event-wrapper > li .event-calender-holder:after {
  content: "\f111";
  position: absolute;
  right: 10px;
  top: 5px;
  color: #002147;
  font-size: 14px;
  font-family: FontAwesome;
}
.event-inner-area .event-wrapper > li .event-calender-holder h3 {
  font-size: 24px;
  color: #002147;
  margin-bottom: 0;
  line-height: 1;
}
.event-inner-area .event-wrapper > li .event-calender-holder p {
  color: #002147;
  margin-bottom: 5px;
  text-transform: uppercase;
}
.event-inner-area .event-wrapper > li .event-calender-holder span {
  line-height: 2.5;
  background: #002147;
  height: 40px;
  display: block;
  color: #ffffff;
}
.event-inner-area .event-wrapper > li .event-content-holder {
  -moz-flex: 3;
  flex: 3 1;
}
.event-inner-area .event-wrapper > li .event-content-holder h3 {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
}
.event-inner-area .event-wrapper > li .event-content-holder h3 a {
  color: #002147;
  transition: all 0.5s ease-out;
}
.event-inner-area .event-wrapper > li .event-content-holder h3 a:hover {
  color: #fdc800;
  transition: all 0.5s ease-out;
}
.event-inner-area .event-wrapper > li .event-content-holder p {
  margin-bottom: 10px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .event-inner-area .event-wrapper > li .event-content-holder p {
    margin-bottom: 58px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .event-inner-area .event-wrapper > li .event-content-holder p {
    margin-bottom: 15px;
  }
}
.event-inner-area .event-wrapper > li .event-content-holder ul li {
  color: #002147;
  font-weight: 500;
  border-right: 1px solid #bdbdbd;
  display: inline-block;
  margin-right: 15px;
  padding-right: 15px;
  line-height: 1;
}
@media (min-width: 768px) and (max-width: 991px) {
  .event-inner-area .event-wrapper > li .event-content-holder ul li {
    margin-right: 12px;
    padding-right: 12px;
  }
}
.event-inner-area .event-wrapper > li .event-content-holder ul li:last-child {
  border-right: 0;
  margin-right: 0;
  padding-right: 0;
}
.event-inner-area .event-btn-holder {
  margin-top: 45px;
  text-align: center;
}
.news-page-area {
  padding: 100px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .news-page-area {
    padding: 90px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .news-page-area {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .news-page-area {
    padding: 70px 0;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .news-page-area {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 320px) {
  .news-page-area {
    padding: 50px 0;
  }
}
.news-box {
  margin-bottom: 55px;
}
.news-box h2 {
  margin-bottom: 12px;
}
.news-box p {
  margin-bottom: 30px;
}
.news-box .news-img-holder {
  margin-bottom: 25px;
  position: relative;
}
.news-date1 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.news-date1 li {
  width: 112px;
  height: 60px;
  font-size: 24px;
  text-align: center;
  line-height: 2.5;
}
@media (min-width: 480px) and (max-width: 991px) {
  .news-date1 li {
    height: 40px;
    font-size: 20px;
    width: 90px;
    line-height: 2;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .news-date1 li {
    font-size: 18px;
    line-height: 2.3;
  }
}
@media only screen and (max-width: 479px) {
  .news-date1 li {
    height: 35px;
    font-size: 16px;
    width: 80px;
  }
}
.news-date1 li:nth-child(odd) {
  background: #002147;
  color: #ffffff;
  font-weight: 700;
}
.news-date1 li:nth-child(even) {
  background: #fdc800;
  color: #212121;
  font-weight: 500;
}
.news-date2 {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.news-date2 li {
  width: 80px;
  height: 50px;
  font-size: 18px;
  text-align: center;
  line-height: 2.8;
}
@media (min-width: 480px) and (max-width: 991px) {
  .news-date2 li {
    height: 40px;
    font-size: 20px;
    width: 90px;
    line-height: 2;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .news-date2 li {
    font-size: 18px;
    line-height: 2.3;
  }
}
@media only screen and (max-width: 479px) {
  .news-date2 li {
    height: 35px;
    font-size: 16px;
    width: 80px;
    line-height: 2.2;
  }
}
.news-date2 li:nth-child(odd) {
  background: #002147;
  color: #ffffff;
  font-weight: 700;
}
.news-date2 li:nth-child(even) {
  background: #fdc800;
  color: #212121;
  font-weight: 500;
}
.news-comments {
  margin-bottom: 35px;
  padding-bottom: 10px;
}
.news-comments li {
  display: inline-block;
  margin-right: 20px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .news-comments li {
    margin-right: 8px;
  }
}
.news-comments li a {
  color: #b1b1b1;
}
.news-comments li a:hover {
  color: #002147;
}
.news-comments li a i {
  color: #fdc800;
  margin-right: 8px;
}
.news-comments li a span {
  color: #444444;
}
.news-details-page-area {
  padding: 100px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .news-details-page-area {
    padding: 90px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .news-details-page-area {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .news-details-page-area {
    padding: 70px 0;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .news-details-page-area {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 320px) {
  .news-details-page-area {
    padding: 50px 0;
  }
}
.news-details-page-inner .news-img-holder {
  margin-bottom: 25px;
  position: relative;
}
.news-details-page-inner .title-default-left-bold {
  margin-bottom: 20px;
}
.news-details-page-inner p span {
  font-style: italic;
  padding: 40px 50px 50px;
  background: #f5f5f5;
  display: block;
  position: relative;
  margin-bottom: 30px;
}
.news-details-page-inner p span:before {
  color: #fdc800;
  content: "\f10d";
  font-family: FontAwesome;
  font-size: 20px;
  left: 30px;
  position: absolute;
  top: 30px;
  z-index: 1;
}
.news-details-page-inner .news-social {
  margin-top: 10px;
  margin-bottom: 80px;
  display: inline-block;
}
.news-details-page-inner .news-social li {
  display: inline-block;
  margin-right: 5px;
}
.news-details-page-inner .news-social li a {
  background: #002147;
  width: 30px;
  height: 30px;
  display: block;
  text-align: center;
  line-height: 27px;
  transition: all 0.3s ease-out;
}
.news-details-page-inner .news-social li a i {
  color: #fdc800;
  font-size: 13px;
  transition: all 0.3s ease-out;
}
.news-details-page-inner .news-social li a:hover {
  background: #fdc800;
}
.news-details-page-inner .news-social li a:hover i {
  color: #002147;
}
.news-details-page-inner .news-social li:last-child {
  margin-right: 0;
}
.event-page-area {
  padding: 100px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .event-page-area {
    padding: 90px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .event-page-area {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .event-page-area {
    padding: 70px 0;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .event-page-area {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 320px) {
  .event-page-area {
    padding: 50px 0;
  }
}
@media only screen and (max-width: 479px) {
  .event-inner-wrapper .col-xs-6 {
    width: 100%;
  }
}
.event-inner-wrapper .single-item {
  margin-bottom: 40px;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
@media only screen and (max-width: 1199px) {
  .event-inner-wrapper .single-item {
    display: block;
  }
}
.event-inner-wrapper .single-item .item-img {
  -moz-flex: 1;
  flex: 1 1;
}
.event-inner-wrapper .single-item .item-content {
  padding: 0 30px;
  -moz-flex: 1;
  flex: 1 1;
}
@media only screen and (max-width: 1199px) {
  .event-inner-wrapper .single-item .item-content {
    padding: 30px 0;
  }
}
.event-inner-wrapper .single-item .item-content p {
  margin-bottom: 30px;
}
.event-inner-wrapper .event-info-block li {
  line-height: 1.4;
  margin-bottom: 15px;
  font-size: 18px;
  color: #002147;
  padding-left: 40px;
  position: relative;
}
@media only screen and (max-width: 479px) {
  .event-inner-wrapper .event-info-block li {
    font-size: 16px;
  }
}
.event-inner-wrapper .event-info-block li:last-child {
  margin-bottom: 0;
}
.event-inner-wrapper .event-info-block li i {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  font-size: 24px;
  margin-right: 15px;
  color: #fdc800;
}
.event-details-page-area {
  padding: 100px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .event-details-page-area {
    padding: 90px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .event-details-page-area {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .event-details-page-area {
    padding: 70px 0;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .event-details-page-area {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 320px) {
  .event-details-page-area {
    padding: 50px 0;
  }
}
.event-details-inner .event-details-img {
  position: relative;
  margin-bottom: 30px;
}
.event-details-inner .event-details-img .countdown-content {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  z-index: 1;
}
@media only screen and (max-width: 767px) {
  .event-details-inner .event-details-img .countdown-content {
    top: inherit;
    bottom: 0;
    transform: translateY(0);
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
  }
}
.event-details-inner .event-details-img .countdown-content .countdown-section {
  padding: 15px 0;
  text-align: center;
  width: 80px;
}
@media only screen and (max-width: 991px) {
  .event-details-inner .event-details-img .countdown-content .countdown-section {
    padding: 8px 0 4px;
    width: 75px;
  }
}
@media only screen and (max-width: 767px) {
  .event-details-inner .event-details-img .countdown-content .countdown-section {
    width: 65px;
    display: inline-block;
  }
}
.event-details-inner .event-details-img .countdown-content .countdown-section h3 {
  color: #ffffff;
  margin-bottom: 0;
  line-height: 1;
  font-size: 18px;
  font-weight: 700;
}
.event-details-inner .event-details-img .countdown-content .countdown-section p {
  margin-bottom: 0;
}
.event-details-inner .event-details-img .countdown-content .countdown-section:nth-child(odd) {
  background: #002147;
}
.event-details-inner .event-details-img .countdown-content .countdown-section:nth-child(odd) p {
  color: #a0a5ac;
}
.event-details-inner .event-details-img .countdown-content .countdown-section:nth-child(even) {
  background: #fdc800;
}
.event-details-inner .event-details-img .countdown-content .countdown-section:nth-child(even) p {
  color: #856900;
}
.event-details-inner p {
  margin-bottom: 50px;
}
.event-info-inline {
  margin-bottom: 55px;
}
.event-info-inline li {
  display: inline-block;
  line-height: 1.4;
  margin-right: 30px;
  font-size: 18px;
  color: #002147;
  padding-left: 40px;
  position: relative;
  margin-bottom: 10px;
}
@media only screen and (max-width: 479px) {
  .event-info-inline li {
    font-size: 16px;
  }
}
.event-info-inline li:last-child {
  margin-bottom: 0;
}
.event-info-inline li i {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  font-size: 24px;
  margin-right: 15px;
  color: #fdc800;
}
.why-choose-area {
  padding: 82px 0 94px;
  background: #f8f8f8;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .why-choose-area {
    padding: 74px 0 84px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .why-choose-area {
    padding: 66px 0 74px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .why-choose-area {
    padding: 58px 0 33px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .why-choose-area {
    padding: 49px 0 23px;
  }
}
@media only screen and (max-width: 320px) {
  .why-choose-area {
    padding: 40px 0 13px;
  }
}
/*=======================================================================
[17] Certificate Area
=========================================================================*/
.certificate-area {
  background: #f8f8f8;
  padding: 100px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .certificate-area {
    padding: 90px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .certificate-area {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .certificate-area {
    padding: 70px 0;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .certificate-area {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 320px) {
  .certificate-area {
    padding: 50px 0;
  }
}
.certificate-area .single-item {
  background: #ffffff;
  padding: 15px;
}
/*=======================================================================
[18] Gallery Area
=========================================================================*/
.gallery-wrapper {
  margin-left: -5px;
  margin-right: -5px;
}
.gallery-wrapper > div {
  padding: 0 3px;
}
.gallery-box {
  margin-bottom: 6px;
  position: relative;
}
.gallery-box:before {
  opacity: 0;
  content: "";
  background-color: rgba(253, 200, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 100%;
  width: 100%;
  transition: all 0.5s ease-out;
}
.gallery-box img {
  width: 100%;
}
.gallery-box .gallery-content {
  opacity: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: all 0.5s ease-out;
}
.gallery-box .gallery-content a {
  background-color: #002147;
  height: 55px;
  width: 55px;
  line-height: 60px;
  display: inline-block;
  border-radius: 50%;
}
.gallery-box .gallery-content a i {
  color: #ffffff;
  font-size: 18px;
  transition: all 0.5s ease-out;
}
.gallery-box:hover:before {
  opacity: 1;
  transition: all 0.5s ease-out;
}
.gallery-box:hover .gallery-content {
  opacity: 1;
  transition: all 0.5s ease-out;
}
.gallery-area1 {
  padding: 100px 0 93px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .gallery-area1 {
    padding: 90px 0 83px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .gallery-area1 {
    padding: 80px 0 73px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .gallery-area1 {
    padding: 70px 0 63px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .gallery-area1 {
    padding: 60px 0 53px;
  }
}
@media only screen and (max-width: 320px) {
  .gallery-area1 {
    padding: 50px 0 43px;
  }
}
.gallery-area2 {
  padding: 100px 0 93px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .gallery-area2 {
    padding: 90px 0 83px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .gallery-area2 {
    padding: 80px 0 73px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .gallery-area2 {
    padding: 70px 0 63px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .gallery-area2 {
    padding: 60px 0 53px;
  }
}
@media only screen and (max-width: 320px) {
  .gallery-area2 {
    padding: 50px 0 43px;
  }
}
/*=======================================================================
[19] Price Table Area
=========================================================================*/
.price-table1-area {
  padding: 100px 0 70px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .price-table1-area {
    padding: 90px 0 60px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .price-table1-area {
    padding: 80px 0 50px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .price-table1-area {
    padding: 70px 0 40px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .price-table1-area {
    padding: 60px 0 30px;
  }
}
@media only screen and (max-width: 320px) {
  .price-table1-area {
    padding: 50px 0 20px;
  }
}
.price-table-box1 {
  text-align: center;
  padding: 50px 0;
  background: #f8f8f8;
  margin: 0 auto 30px;
  transition: all 0.5s ease-out;
}
.price-table-box1 span {
  text-transform: uppercase;
  color: #002147;
  font-size: 24px;
  font-weight: 500;
  display: block;
  margin-bottom: 30px;
  transition: all 0.5s ease-out;
}
.price-table-box1 h2 {
  color: #ffffff;
  font-size: 30px;
  height: 150px;
  width: 150px;
  line-height: 150px;
  display: inline-block;
  background: #002147;
  border-radius: 50%;
  margin-bottom: 35px;
  transition: all 0.5s ease-out;
}
.price-table-box1 .price-table-service {
  margin-bottom: 40px;
}
.price-table-box1 .price-table-service p {
  color: #ffffff;
  font-size: 16px;
  color: #444444;
  line-height: 1;
  display: block;
  transition: all 0.5s ease-out;
}
.price-table-box1:hover {
  background: #002147;
  box-shadow: 0px 5px 12px 0px rgba(0, 0, 0, 0.5);
}
.price-table-box1:hover span {
  color: #ffffff;
}
.price-table-box1:hover h2 {
  background: #fdc800;
  color: #002147;
}
.price-table-box1:hover .price-table-service p {
  color: #d2d2d2;
}
.price-table2-area {
  padding: 100px 0 70px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .price-table2-area {
    padding: 90px 0 60px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .price-table2-area {
    padding: 80px 0 50px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .price-table2-area {
    padding: 70px 0 40px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .price-table2-area {
    padding: 60px 0 30px;
  }
}
@media only screen and (max-width: 320px) {
  .price-table2-area {
    padding: 50px 0 20px;
  }
}
.price-table-box2 {
  text-align: center;
  margin-bottom: 30px;
  background: #f8f8f8;
  padding: 70px 0;
}
.price-table-box2 span {
  text-transform: uppercase;
  font-size: 24px;
  font-weight: 500;
  color: #002147;
  letter-spacing: 4px;
  margin-bottom: 25px;
  display: inline-block;
}
.price-table-box2 h3 {
  font-size: 48px;
  color: #fdc800;
  margin-bottom: 35px;
}
.price-table-box2 h3 span {
  text-transform: capitalize;
  font-size: 18px;
  color: #a4a4a4;
}
.price-table-box2 ul {
  margin-bottom: 20px;
}
.price-table-box2 ul li {
  color: #002147;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 10px;
}
/*=======================================================================
[20] Research Area
=========================================================================*/
.research-page1-area {
  padding: 100px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .research-page1-area {
    padding: 90px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .research-page1-area {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .research-page1-area {
    padding: 70px 0;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .research-page1-area {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 320px) {
  .research-page1-area {
    padding: 50px 0;
  }
}
.research-box1 {
  overflow: hidden;
  margin-bottom: 45px;
}
.research-box1 img {
  margin-bottom: 25px;
}
.research-page2-area {
  padding: 100px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .research-page2-area {
    padding: 90px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .research-page2-area {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .research-page2-area {
    padding: 70px 0;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .research-page2-area {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 320px) {
  .research-page2-area {
    padding: 50px 0;
  }
}
.research-box2 {
  margin-bottom: 35px;
}
.research-box2 .research-img-holder {
  margin-bottom: 20px;
  position: relative;
}
.research-box2 .research-img-holder .research-details {
  position: absolute;
  bottom: 30px;
  z-index: 1;
  left: 30px;
}
.research-box2 .research-img-holder .research-details a {
  color: #ffffff;
  padding: 10px 20px;
  display: block;
  background: #002147;
  font-size: 18px;
  font-weight: 500;
  transition: all 0.5s ease-out;
}
.research-box2 .research-img-holder .research-details a:hover {
  background: #fdc800;
  color: #212121;
}
.research-box2 .research-img-holder .research-details a:hover i {
  color: #212121;
}
.research-box2 .research-img-holder .research-details a i {
  color: #fdc800;
  font-size: 20px;
  margin-left: 15px;
  font-weight: 700;
  transition: all 0.5s ease-out;
}
.research-page3-area {
  padding: 100px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .research-page3-area {
    padding: 90px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .research-page3-area {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .research-page3-area {
    padding: 70px 0;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .research-page3-area {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 320px) {
  .research-page3-area {
    padding: 50px 0;
  }
}
.research-box3 {
  margin-bottom: 60px;
}
.research-box3 img {
  margin-bottom: 20px;
}
.research-box3 p {
  margin-bottom: 30px;
}
.research-details-page-area {
  padding: 100px 0 74px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .research-details-page-area {
    padding: 90px 0 77px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .research-details-page-area {
    padding: 80px 0 52px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .research-details-page-area {
    padding: 70px 0 69px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .research-details-page-area {
    padding: 60px 0 59px;
  }
}
@media only screen and (max-width: 320px) {
  .research-details-page-area {
    padding: 50px 0 49px;
  }
}
.research-details-inner img {
  margin-bottom: 20px;
}
.research-details-inner p span {
  font-style: italic;
  padding: 40px 50px 50px;
  background: #f5f5f5;
  display: block;
  position: relative;
  margin-bottom: 30px;
}
.research-details-inner p span:before {
  color: #fdc800;
  content: "\f10d";
  font-family: FontAwesome;
  font-size: 20px;
  left: 30px;
  position: absolute;
  top: 30px;
  z-index: 1;
}
/*=======================================================================
[21] Shop Page Area
=========================================================================*/
.shop-page1-area {
  padding: 100px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .shop-page1-area {
    padding: 90px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .shop-page1-area {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .shop-page1-area {
    padding: 70px 0;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .shop-page1-area {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 320px) {
  .shop-page1-area {
    padding: 50px 0;
  }
}
@media only screen and (max-width: 479px) {
  .shop-page1-area .shop-page1-xs-width .col-xs-6 {
    width: 100%;
  }
}
.product-box1 {
  text-align: center;
  margin-bottom: 30px;
}
.product-box1 .product-social {
  opacity: 0;
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 33%;
  z-index: 2;
  transform: scale(1) translateY(-150px);
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 479px) {
  .product-box1 .product-social {
    top: 27%;
    transform: scale(1) translateY(-50px);
  }
}
.product-box1 .product-social li {
  display: inline-block;
  height: 40px;
  width: 42px;
  border: 1px solid #fdc800;
  background: transparent;
  margin: 0 1px;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 479px) {
  .product-box1 .product-social li {
    height: 30px;
    width: 32px;
  }
}
.product-box1 .product-social li a {
  display: block;
}
.product-box1 .product-social li a i {
  line-height: 40px;
  color: #ffffff;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 479px) {
  .product-box1 .product-social li a i {
    line-height: 30px;
  }
}
.product-box1 .product-social li:hover {
  background: #fdc800;
}
.product-box1 .product-social li:hover i {
  color: #212121;
}
.product-box1 .product-img-holder {
  position: relative;
}
@media only screen and (max-width: 479px) {
  .product-box1 .product-img-holder {
    display: inline-block;
  }
}
.product-box1 .product-img-holder a img {
  width: 100%;
}
@media only screen and (max-width: 479px) {
  .product-box1 .product-img-holder a img {
    width: auto;
  }
}
.product-box1 .product-img-holder:after {
  opacity: 0;
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background-color: rgba(0, 33, 71, 0.8);
  z-index: 1;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  transition: all 0.5s ease-out;
}
.product-box1 .product-img-holder .hot-sale {
  top: 0;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 60px solid #fdc800;
  border-right: 60px solid transparent;
  z-index: 3;
}
.product-box1 .product-img-holder .hot-sale span {
  color: #002147;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  bottom: 26px;
  letter-spacing: 2px;
  position: absolute;
  -webkit-transform: rotate(316deg);
  -moz-transform: rotate(316deg);
  -o-transform: rotate(316deg);
}
.product-box1 .product-content-holder {
  margin-top: 20px;
}
.product-box1 .product-content-holder h3 {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 0;
}
@media only screen and (max-width: 479px) {
  .product-box1 .product-content-holder h3 {
    letter-spacing: 0;
  }
}
.product-box1 .product-content-holder h3 a {
  color: #002147;
  transition: all 0.5s ease-out;
}
.product-box1 .product-content-holder h3 a:hover {
  color: #fdc800;
}
.product-box1 .product-content-holder span {
  color: #fdc800;
  font-size: 20px;
  font-weight: 500;
}
.product-box1 .product-content-holder span span {
  margin-right: 15px;
  color: #757575;
  text-decoration: line-through;
}
.product-box1:hover .product-social {
  top: 40%;
  opacity: 1;
  transform: scale(1) translateY(-50%);
  transition: all 0.5s ease-out;
}
.product-box1:hover .product-img-holder:after {
  opacity: 1;
  transition: all 0.5s ease-out;
}
.shop-page2-area {
  padding: 100px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .shop-page2-area {
    padding: 90px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .shop-page2-area {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .shop-page2-area {
    padding: 70px 0;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .shop-page2-area {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 320px) {
  .shop-page2-area {
    padding: 50px 0;
  }
}
.product-box2 {
  margin-bottom: 30px;
}
@media only screen and (max-width: 479px) {
  .product-box2 {
    text-align: center;
    margin-bottom: 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .product-box2 a {
    float: none!important;
  }
}
@media only screen and (max-width: 479px) {
  .product-box2 a {
    float: none!important;
  }
}
.product-box2 a img {
  width: 100%;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 479px) {
  .product-box2 a img {
    display: inline-block;
    width: auto;
    margin-bottom: 15px;
  }
}
.product-box2 a img:hover {
  opacity: 0.5;
  transition: all 0.5s ease-out;
}
.product-box2 .media-body {
  text-align: left;
  padding: 0 30px 0 20px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .product-box2 .media-body {
    padding: 0;
  }
}
@media only screen and (max-width: 479px) {
  .product-box2 .media-body {
    padding: 8px 10px 0 10px;
  }
}
@media only screen and (max-width: 479px) {
  .product-box2 .media-body .product-box2-content {
    text-align: center;
  }
}
.product-box2 .media-body .product-box2-content h3 {
  font-size: 20px;
  text-transform: capitalize;
  letter-spacing: 1px;
  margin-bottom: 10px;
  font-weight: 500;
}
.product-box2 .media-body .product-box2-content h3 a {
  color: #002147;
  transition: all 0.5s ease-out;
}
.product-box2 .media-body .product-box2-content h3 a:hover {
  color: #fdc800;
}
.product-box2 .media-body .product-box2-content span {
  font-size: 20px;
  display: block;
  margin-bottom: 20px;
  color: #fdc800;
  font-weight: 500;
}
.product-box2 .media-body .product-box2-cart {
  margin-top: 30px;
}
@media only screen and (max-width: 1199px) {
  .product-box2 .media-body .product-box2-cart {
    margin-top: 54px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .product-box2 .media-body .product-box2-cart {
    margin-top: 10px;
  }
}
@media only screen and (max-width: 479px) {
  .product-box2 .media-body .product-box2-cart {
    margin-top: 20px;
    text-align: center;
  }
}
.product-box2 .media-body .product-box2-cart li {
  margin: 0 2px 0 0;
  display: inline-block;
}
.product-box2 .media-body .product-box2-cart li a {
  line-height: 42px;
  border: 1px solid #002147;
  text-align: center;
  height: 42px;
  padding: 0 20px;
  background: transparent;
  display: block;
  color: #002147;
  text-transform: uppercase;
  font-weight: 500;
  transition: all 0.5s ease-out;
}
@media (min-width: 768px) and (max-width: 991px) {
  .product-box2 .media-body .product-box2-cart li a {
    padding: 0 11px;
  }
}
@media only screen and (max-width: 320px) {
  .product-box2 .media-body .product-box2-cart li a {
    padding: 0 15px;
  }
}
.product-box2 .media-body .product-box2-cart li a i {
  line-height: 42px;
  font-size: 14px;
}
.product-box2 .media-body .product-box2-cart li a:hover {
  border: 1px solid #fdc800;
  background: #fdc800;
  color: #002147;
  transition: all 0.5s ease-out;
}
.product-box2 .media-body .product-box2-cart li:first-child a {
  border: 1px solid #002147;
  background: #002147;
  color: #ffffff;
  transition: all 0.5s ease-out;
}
.product-box2 .media-body .product-box2-cart li:first-child a:hover {
  border: 1px solid #fdc800;
  background: #fdc800;
  color: #002147;
  transition: all 0.5s ease-out;
}
.shop-details-page-area {
  padding: 100px 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .shop-details-page-area {
    padding: 90px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .shop-details-page-area {
    padding: 80px 0 52px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .shop-details-page-area {
    padding: 70px 0;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .shop-details-page-area {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 320px) {
  .shop-details-page-area {
    padding: 50px 0;
  }
}
@media only screen and (max-width: 767px) {
  .inner-product-details-left {
    text-align: center;
  }
}
.inner-product-details-left .tab-content {
  border: 1px solid #002147;
  margin-top: 0;
}
@media only screen and (max-width: 767px) {
  .inner-product-details-left .tab-content {
    display: inline-block;
  }
}
.inner-product-details-left .tab-content a {
  display: block;
}
.inner-product-details-left .tab-content a img {
  width: 100%;
}
@media only screen and (max-width: 991px) {
  .inner-product-details-left ul {
    margin-bottom: 30px;
  }
}
.inner-product-details-left ul li {
  border: 1px solid transparent;
  width: 29%;
  display: inline-block;
  margin: 16px 17px 0 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .inner-product-details-left ul li {
    width: 28%;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .inner-product-details-left ul li {
    width: 30%;
  }
}
@media only screen and (max-width: 320px) {
  .inner-product-details-left ul li {
    width: 28%;
  }
}
.inner-product-details-left ul li a img {
  width: 100%;
  opacity: 0.5;
}
.inner-product-details-left ul li a img:hover {
  opacity: 1;
}
.inner-product-details-left ul li:last-child {
  margin: 16px 0 0 0;
}
.inner-product-details-left ul li:hover {
  border: 1px solid #002147;
}
.inner-product-details-left ul .active {
  border: 1px solid #002147;
}
.inner-product-details-left ul .active a img {
  opacity: 1;
}
.inner-product-details-right h3 {
  color: #002147;
  font-weight: 700;
  text-transform: capitalize;
  margin-bottom: 15px;
  font-size: 30px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .inner-product-details-right h3 {
    margin-bottom: 15px;
  }
}
.inner-product-details-right > ul {
  margin: 0 0 15px;
}
.inner-product-details-right > ul li {
  display: inline-block;
}
.inner-product-details-right > ul li > i {
  color: #eebe2c;
}
.inner-product-details-right .price {
  font-size: 24px;
  font-weight: 600;
  color: #002147;
  margin-bottom: 30px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .inner-product-details-right .price {
    margin-bottom: 40px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .inner-product-details-right .price {
    margin-bottom: 30px;
  }
}
.inner-product-details-right .product-details-content {
  margin-bottom: 30px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .inner-product-details-right .product-details-content {
    margin-bottom: 24px;
  }
}
@media only screen and (max-width: 991px) {
  .inner-product-details-right .product-details-content {
    margin-bottom: 12px;
  }
}
.inner-product-details-right .product-details-content > p {
  margin-bottom: 10px;
  color: #757575;
}
.inner-product-details-right .product-details-content > p > span {
  color: #212121;
  font-weight: 600;
  text-transform: uppercase;
}
.inner-product-details-right .product-details-social {
  margin-bottom: 30px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .inner-product-details-right .product-details-social {
    margin-bottom: 52px;
  }
}
@media only screen and (max-width: 991px) {
  .inner-product-details-right .product-details-social {
    margin-bottom: 20px;
  }
}
.inner-product-details-right .product-details-social li {
  display: inline;
  color: #212121;
  font-weight: 600;
  margin-right: 15px;
}
.inner-product-details-right .product-details-social li a {
  padding: 0 10px;
}
.inner-product-details-right .product-details-social li a i {
  color: #757575;
  transition: all 0.5s ease-out;
}
.inner-product-details-right .product-details-social li a:hover i {
  color: #fdc800;
}
.inner-product-details-right .inner-product-details-cart {
  margin-bottom: 0;
}
@media only screen and (max-width: 479px) {
  .inner-product-details-right .inner-product-details-cart {
    text-align: center;
  }
}
.inner-product-details-right .inner-product-details-cart li {
  vertical-align: top;
  margin: 0 2px 0 0;
  display: inline-block;
}
@media only screen and (max-width: 479px) {
  .inner-product-details-right .inner-product-details-cart li {
    margin-bottom: 5px;
  }
}
.inner-product-details-right .inner-product-details-cart li a {
  border: 1px solid #002147;
  line-height: 52px;
  text-align: center;
  height: 52px;
  padding: 0 20px;
  background: transparent;
  display: block;
  color: #002147;
  text-transform: uppercase;
  font-weight: 500;
  transition: all 0.5s ease-out;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .inner-product-details-right .inner-product-details-cart li a {
    padding: 0 13px;
  }
}
.inner-product-details-right .inner-product-details-cart li a i {
  color: #002147;
  line-height: 54px;
  font-size: 14px;
  transition: all 0.5s ease-out;
}
.inner-product-details-right .inner-product-details-cart li a:hover {
  border: 1px solid #fdc800;
  background: #fdc800;
}
.inner-product-details-right .inner-product-details-cart li .input-group {
  position: relative;
  box-shadow: none;
}
.inner-product-details-right .inner-product-details-cart li .input-group .form-control {
  box-shadow: none;
}
.inner-product-details-right .inner-product-details-cart li .input-group input {
  border: none;
  height: 50px;
  width: 100px;
  padding-right: 47px;
  font-size: 20px;
  text-align: center;
  color: #002147;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .inner-product-details-right .inner-product-details-cart li .input-group input {
    width: 81px;
    padding-left: 5px;
    padding-right: 40px;
  }
}
.inner-product-details-right .inner-product-details-cart li .input-group .input-group-btn-vertical {
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
}
.inner-product-details-right .inner-product-details-cart li .input-group .input-group-btn-vertical .btn-default {
  border: 0;
}
.inner-product-details-right .inner-product-details-cart li .input-group .input-group-btn-vertical button {
  display: block;
  border-left: 1px solid #002147 !important;
  border-radius: 0!important;
  background: transparent;
  padding: 4px 12px 3px;
  line-height: 1.3;
  border: 1px solid #002147;
}
.inner-product-details-right .inner-product-details-cart li .input-group .input-group-btn-vertical button:last-child {
  border-top: 1px solid #002147;
}
.inner-product-details-right .inner-product-details-cart li .input-group .input-group-btn-vertical button i {
  line-height: 1;
  color: #002147;
}
.inner-product-details-right .inner-product-details-cart li:first-child {
  background: #002147;
}
.inner-product-details-right .inner-product-details-cart li:first-child a {
  color: #ffffff;
}
.inner-product-details-right .inner-product-details-cart li:first-child a:hover {
  color: #002147;
}
.inner-product-details-right .inner-product-details-cart li .quantity-holder {
  border: 1px solid #002147;
}
.product-details-tab-area {
  margin: 40px 0;
  border: 1px solid #bdbdbd;
  overflow: hidden;
}
.product-details-tab-area ul > li {
  margin: -1px;
  display: inline-block;
  padding: 10px 0;
  background: #002147;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 479px) {
  .product-details-tab-area ul > li {
    display: block!important;
    padding: 0;
  }
}
.product-details-tab-area ul > li a {
  color: #ffffff;
  padding: 12px 30px;
  text-transform: uppercase;
  font-weight: 500;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 479px) {
  .product-details-tab-area ul > li a {
    display: block!important;
  }
}
.product-details-tab-area ul > li:hover {
  background: transparent;
}
.product-details-tab-area ul > li:hover a {
  color: #002147;
}
.product-details-tab-area ul .active {
  background: transparent;
}
.product-details-tab-area ul .active a {
  color: #002147;
  text-decoration: none;
}
.product-details-tab-area .tab-content {
  padding: 10px 35px 15px 35px;
}
@media only screen and (max-width: 479px) {
  .product-details-tab-area .tab-content {
    padding: 0 20px 10px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .product-tags {
    display: none;
  }
}
/*=======================================================================
[22] Brand Area
=========================================================================*/
.brand-area {
  padding: 70px 0;
  text-align: center;
}
.brand-area .brand-area-box a img {
  width: 100%;
  margin: 0 auto;
}
.brand-area .owl-nav {
  margin-top: 0;
}
/*=======================================================================
[23] Sidebar Area
=========================================================================*/
@media only screen and (max-width: 767px) {
  .sidebar {
    margin-top: 30px;
  }
}
.sidebar-box {
  margin-bottom: 30px;
  background: #ffffff;
  border: 1px solid #e0e0e0;
}
.sidebar-box:last-child {
  margin-bottom: 0;
}
.sidebar-box .sidebar-box-inner {
  padding: 30px 25px;
}
@media (min-width: 768px) and (max-width: 1199px) {
  .sidebar-box .sidebar-box-inner {
    padding: 30px 15px;
  }
}
.sidebar-categories li {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e0e0e0;
}
.sidebar-categories li:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  padding-bottom: 0;
}
.sidebar-categories li a {
  color: #444444;
  transition: all 0.5s ease-out;
}
.sidebar-categories li a:hover {
  color: #fdc800;
}
.product-tags li {
  display: inline-block;
  margin-bottom: 7px;
  background: #e1e1e1;
  font-weight: 500;
  transition: all 0.5s ease-out;
}
.product-tags li a {
  color: #002147;
  text-transform: capitalize;
  display: block;
  padding: 10px 24px;
  transition: all 0.5s ease-out;
}
.product-tags li:hover {
  background: #002147;
}
.product-tags li:hover a {
  color: #ffffff;
}
.sidebar-add-area {
  position: relative;
}
.sidebar-add-area img {
  width: 100%;
}
.sidebar-add-area a {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 2;
}
.price-range-wrapper {
  padding-top: 10px;
}
.price-range-wrapper:after {
  clear: both;
  display: block;
  content: "";
}
.price-range-wrapper .price-range-select {
  margin: 20px 0;
  text-align: center;
}
.price-range-wrapper .price-range-select:after {
  clear: both;
  display: block;
  content: "";
}
.price-range-wrapper .price-range-select .price-range {
  font-size: 24px;
  color: #002147;
  display: inline-block;
  margin: 0 10px;
}
.price-range-wrapper .noUi-connect {
  background: #002147;
}
.price-range-wrapper .noUi-horizontal {
  height: 10px;
}
.price-range-wrapper .noUi-target {
  border-radius: 0;
}
.price-range-wrapper .noUi-horizontal .noUi-handle {
  top: -10px;
  background: #fdc800;
  border: none;
  box-shadow: none;
}
.price-range-wrapper .noUi-handle::after,
.price-range-wrapper .noUi-handle::before {
  left: 16px;
  top: 7px;
  background: #ffffff;
}
.price-range-wrapper .noUi-handle::after {
  left: 19px;
}
.sidebar-find-course .form-group:last-child {
  margin-bottom: 0;
}
.sidebar-find-course .form-group input {
  border-radius: 0;
  background: #f5f5f5;
  color: #002147;
  border: none;
}
.sidebar-find-course .form-group .select2-selection.select2-selection--single {
  border-radius: 0;
  height: 40px;
  background-image: none;
  border: 1px solid #e5e5e5;
}
.sidebar-find-course .course-name input {
  height: 40px;
  border: 1px solid #e5e5e5;
  box-shadow: none;
}
.sidebar-find-course .remember-style {
  margin: 25px 0;
}
.sidebar-find-course .remember-style span {
  color: #002147;
  margin-right: 15px;
  text-transform: uppercase;
}
.sidebar-find-course .remember-style span input {
  margin-right: 10px;
}
.sidebar-find-course input.form-control::-webkit-input-placeholder,
.sidebar-find-course textarea.form-control::-webkit-input-placeholder {
  color: #9a9a9a;
  font-style: italic;
}
.sidebar-find-course input.form-control::-moz-placeholder,
.sidebar-find-course textarea.form-control::-moz-placeholder {
  color: #9a9a9a;
  font-style: italic;
}
.sidebar-find-course input.form-control:-moz-placeholder,
.sidebar-find-course textarea.form-control:-moz-placeholder {
  color: #9a9a9a;
  font-style: italic;
}
.sidebar-find-course input.form-control:-ms-input-placeholder,
.sidebar-find-course textarea.form-control:-ms-input-placeholder {
  color: #9a9a9a;
  font-style: italic;
}
.select2-container--classic .select2-selection--single .select2-selection__arrow {
  background: none;
  border: none;
  margin: 5px 5px 0 0;
}
.select2-container--classic .select2-selection--single .select2-selection__rendered {
  padding: 5px 0 0 10px;
  line-height: inherit;
  font-style: italic;
  color: #9a9a9a;
}
.select2-container.select2-container--classic.select2-container--open {
  width: 200px!important;
}
.sidebar-course-price span {
  font-size: 40px;
  color: #002147;
  margin-bottom: 20px;
  display: block;
}
.sidebar-course-price .enroll-btn {
  margin-bottom: 30px;
}
.sidebar-course-reviews h4 {
  color: #002147;
  font-size: 16px;
  position: relative;
  margin-bottom: 10px;
}
.sidebar-course-reviews h4 span {
  position: absolute;
  right: 0;
  top: -15px;
  z-index: 1;
  font-size: 36px;
  color: #002147;
}
.sidebar-course-reviews ul li {
  display: inline-block;
  color: #fdc800;
}
.skill-area {
  position: relative;
  overflow: hidden;
}
.skill-area .progress {
  background-color: #f0f0f0;
  border-radius: 0;
  box-shadow: none;
  height: 8px;
  overflow: visible;
  position: relative;
  margin-top: 45px;
}
.skill-area .progress .lead {
  color: #002147;
  position: absolute;
  top: -30px;
  font-weight: 500;
}
.skill-area .progress:last-child {
  margin-bottom: 0;
}
.skill-area .progress > span {
  color: #002147;
  position: absolute;
  right: 0;
  top: -30px;
}
.skill-area .progress:nth-child(1) .progress-bar {
  background: #fdc800;
}
.skill-area .progress:nth-child(2) .progress-bar {
  background: #fdc800;
}
.skill-area .progress:nth-child(3) .progress-bar {
  background: #fdc800;
}
.skill-area .progress:nth-child(4) .progress-bar {
  background: #fdc800;
}
.skill-area .progress:nth-child(5) .progress-bar {
  background: #fdc800;
}
.sidebar-skilled-area ul li {
  margin-bottom: 20px;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.sidebar-skilled-area ul li:last-child {
  margin-bottom: 0;
}
.sidebar-skilled-area ul li .skilled-img {
  -moz-flex: 2;
  flex: 2 1;
  margin-right: 5px;
}
.sidebar-skilled-area ul li .skilled-content {
  -moz-flex: 3;
  flex: 3 1;
}
.sidebar-skilled-area ul li .skilled-content h4 {
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 0.9;
  font-weight: 500;
}
.sidebar-skilled-area ul li .skilled-content h4 a {
  color: #002147;
  transition: all 0.5s ease-out;
}
.sidebar-skilled-area ul li .skilled-content h4 a:hover {
  color: #212121;
}
.sidebar-skilled-area ul li .skilled-content p {
  color: #a2a2a2;
  line-height: 1.2;
  margin-bottom: 0;
}
.sidebar-related-area ul li {
  border-bottom: 1px solid #d5d5d5;
  margin-bottom: 20px;
  padding-bottom: 20px;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.sidebar-related-area ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.sidebar-related-area ul li .related-img {
  -moz-flex: 2;
  flex: 2 1;
  margin-right: 10px;
}
.sidebar-related-area ul li .related-content {
  -moz-flex: 3;
  flex: 3 1;
}
.sidebar-related-area ul li .related-content h4 {
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 0.9;
  color: #002147;
}
.sidebar-related-area ul li .related-content h4 a {
  color: #002147;
  transition: all 0.5s ease-out;
}
.sidebar-related-area ul li .related-content h4 a:hover {
  color: #444444;
}
.sidebar-related-area ul li .related-content p {
  color: #000000;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 0;
  font-weight: 500;
}
.sidebar-best-seller-area ul li {
  border-bottom: 1px solid #d5d5d5;
  margin-bottom: 20px;
  padding-bottom: 20px;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.sidebar-best-seller-area ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.sidebar-best-seller-area ul li .related-img {
  -moz-flex: 2;
  flex: 2 1;
  margin-right: 10px;
}
.sidebar-best-seller-area ul li .related-content {
  -moz-flex: 3;
  flex: 3 1;
}
.sidebar-best-seller-area ul li .related-content h4 {
  font-size: 16px;
  margin-bottom: 10px;
  line-height: 0.9;
}
.sidebar-best-seller-area ul li .related-content h4 a {
  color: #002147;
  transition: all 0.5s ease-out;
}
.sidebar-best-seller-area ul li .related-content h4 a:hover {
  color: #fdc800;
}
.sidebar-best-seller-area ul li .related-content .rating {
  margin-bottom: 12px;
}
.sidebar-best-seller-area ul li .related-content .rating li {
  display: inline;
  border-bottom: none;
}
.sidebar-best-seller-area ul li .related-content .rating li i {
  color: #fdc800;
}
.sidebar-best-seller-area ul li .related-content p {
  color: #002147;
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 500;
}
.sidebar-question-form .form-group:last-child {
  margin-bottom: 0;
}
.sidebar-question-form .form-group input {
  border-radius: 0;
  background: #f5f5f5;
  color: #002147;
  height: 40px;
  border: 1px solid #e5e5e5;
  box-shadow: none;
}
.sidebar-question-form .form-group textarea {
  border-radius: 0;
  background: #f5f5f5;
  color: #002147;
  border: 1px solid #e5e5e5;
  box-shadow: none;
}
.sidebar-question-form .form-response .alert-info {
  background: transparent;
  border: none;
  color: #ffffff;
  letter-spacing: 2px;
}
.sidebar-question-form .form-response .alert-success {
  background-color: transparent;
  color: #05e42a;
  letter-spacing: 1px;
  border-color: transparent;
  font-weight: 500;
}
@media only screen and (max-width: 479px) {
  .sidebar-question-form .form-response .alert-success {
    letter-spacing: 0;
  }
}
.sidebar-question-form .form-response .alert {
  border-radius: 0;
  padding: 15px 0;
  margin-bottom: 0;
  margin-top: 15px;
}
.sidebar-latest-research-area ul li {
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 25px;
  padding-bottom: 22px;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
}
.sidebar-latest-research-area ul li:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.sidebar-latest-research-area ul li .latest-research-img {
  -moz-flex: 2;
  flex: 2 1;
  margin-right: 10px;
}
.sidebar-latest-research-area ul li .latest-research-img a img {
  transition: all 0.5s ease-out;
}
.sidebar-latest-research-area ul li .latest-research-img a:hover img {
  opacity: 0.7;
  transition: all 0.5s ease-out;
}
.sidebar-latest-research-area ul li .latest-research-content {
  -moz-flex: 3;
  flex: 3 1;
}
.sidebar-latest-research-area ul li .latest-research-content h4 {
  font-size: 12px;
  margin-bottom: 6px;
  line-height: 0.9;
  font-weight: 500;
  background: #002147;
  color: #ffffff;
  padding: 7px 10px 5px;
  display: inline-block;
}
.sidebar-latest-research-area ul li .latest-research-content p {
  line-height: 1.2;
  margin-bottom: 0;
}
/*=======================================================================
[24] Faq Page area
=========================================================================*/
.faq-page-area {
  padding: 100px 0 60px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .faq-page-area {
    padding: 90px 0 50px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .faq-page-area {
    padding: 80px 0 40px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .faq-page-area {
    padding: 70px 0 30px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .faq-page-area {
    padding: 60px 0 20px;
  }
}
@media only screen and (max-width: 320px) {
  .faq-page-area {
    padding: 50px 0 10px;
  }
}
.faq-box-wrapper {
  margin-bottom: 20px;
}
.faq-box-wrapper .faq-box-item .panel-heading {
  border-radius: 0;
  padding: 0;
  color: #002147;
}
.faq-box-wrapper .faq-box-item .faq-box-title h3 {
  margin-bottom: 0;
  position: relative;
  font-size: 18px;
}
.faq-box-wrapper .faq-box-item .faq-box-title h3 a {
  display: block;
  position: relative;
  padding: 15px 10px 15px 60px;
  color: #002147;
}
.faq-box-wrapper .faq-box-item .faq-box-title h3 a span {
  position: absolute;
  left: 10px;
  top: 8px;
  z-index: 1;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  line-height: 40px;
  display: inline-block;
  text-align: center;
  font-size: 20px;
  background: #fdc800;
  color: #002147;
  transition: all 0.5s ease-out;
}
.faq-box-wrapper .faq-box-item .faq-box-title h3 a:before {
  content: "\f0fe";
  position: absolute;
  z-index: 1;
  top: 15px;
  right: 15px;
  color: #fdc800;
  font-family: FontAwesome;
}
.faq-box-wrapper .faq-box-item .faq-box-body {
  background: #002147;
  color: #ffffff;
  padding: 20px 35px 5px;
}
.faq-box-wrapper .faq-box-item .active {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}
.faq-box-wrapper .faq-box-item .active .faq-box-title h3 a:before {
  content: "\f146";
  color: #002147;
}
.faq-box-wrapper .faq-box-item .active .faq-box-title h3 a span {
  background: #ffffff;
}
/*=======================================================================
[25] Error Page Area
=========================================================================*/
.error-page-area {
  padding: 100px 0;
  background: #fdc800;
  text-align: center;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .error-page-area {
    padding: 90px 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .error-page-area {
    padding: 80px 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .error-page-area {
    padding: 70px 0;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .error-page-area {
    padding: 60px 0;
  }
}
@media only screen and (max-width: 320px) {
  .error-page-area {
    padding: 50px 0;
  }
}
.error-page-area .error-top {
  margin-bottom: 55px;
}
.error-page-area .error-top img {
  display: inline-block;
}
.error-page-area .error-bottom h2 {
  font-weight: 500;
  margin-bottom: 10px;
  font-size: 30px;
  color: #000000;
}
@media only screen and (max-width: 479px) {
  .error-page-area .error-bottom h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }
}
.error-page-area .error-bottom p {
  color: #634e00;
  font-size: 18px;
  margin-bottom: 40px;
}
/*=======================================================================
[26] Contact Page Area
=========================================================================*/
.contact-us-page1-area {
  padding: 90px 0 0;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .contact-us-page1-area {
    padding: 80px 0 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .contact-us-page1-area {
    padding: 70px 0 0;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .contact-us-page1-area {
    padding: 60px 0 0;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .contact-us-page1-area {
    padding: 50px 0 0;
  }
}
@media only screen and (max-width: 320px) {
  .contact-us-page1-area {
    padding: 40px 0 0;
  }
}
@media only screen and (max-width: 767px) {
  .contact-us-info1 {
    margin-bottom: 30px;
  }
}
.contact-us-info1 h2 {
  font-size: 24px;
  color: #212121;
  text-transform: uppercase;
}
.contact-us-info1 ul {
  margin-top: 11px;
  border: 1px solid #bdbdbd;
}
.contact-us-info1 ul > li {
  padding: 15px 0 8px 30px;
  position: relative;
  border-bottom: 1px solid #bdbdbd;
}
@media only screen and (max-width: 1199px) {
  .contact-us-info1 ul > li {
    padding: 13px 0 6px 30px;
  }
}
@media only screen and (max-width: 991px) {
  .contact-us-info1 ul > li {
    padding: 15px 0 6px 12px;
  }
}
@media only screen and (max-width: 767px) {
  .contact-us-info1 ul > li {
    padding: 13px 0 4px 30px;
  }
}
.contact-us-info1 ul > li:last-child {
  border-bottom: 0;
}
.contact-us-info1 ul > li i {
  color: #002147;
  position: absolute;
  z-index: 1;
  right: 30px;
  top: 40px;
  font-size: 24px;
}
@media only screen and (max-width: 991px) {
  .contact-us-info1 ul > li i {
    top: 20px;
  }
}
.contact-us-info1 ul > li h3 {
  text-transform: capitalize;
  color: #111111;
  font-size: 20px;
  margin-bottom: 5px;
}
.contact-us-info1 ul > li .contact-social {
  margin-bottom: 10px;
  border: none;
}
@media only screen and (max-width: 767px) {
  .contact-us-info1 ul > li .contact-social {
    margin-bottom: 20px;
  }
}
.contact-us-info1 ul > li .contact-social li {
  display: inline;
  margin-right: 15px;
  padding: 0;
  border: none;
}
@media only screen and (max-width: 1199px) {
  .contact-us-info1 ul > li .contact-social li {
    margin-right: 7px;
  }
}
@media only screen and (max-width: 1199px) {
  .contact-us-info1 ul > li .contact-social li {
    margin-right: 0;
  }
}
@media only screen and (max-width: 991px) {
  .contact-us-info1 ul > li .contact-social li {
    margin-right: 2px;
  }
}
.contact-us-info1 ul > li .contact-social li a {
  background: transparent;
  border: 1px solid #002147;
  height: 35px;
  width: 35px;
  line-height: 35px;
  display: inline-block;
  text-align: center;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 991px) {
  .contact-us-info1 ul > li .contact-social li a {
    height: 28px;
    width: 28px;
    line-height: 28px;
  }
}
.contact-us-info1 ul > li .contact-social li a i {
  font-size: 20px;
  position: inherit;
  color: #002147;
  transition: all 0.5s ease-out;
}
@media only screen and (max-width: 991px) {
  .contact-us-info1 ul > li .contact-social li a i {
    font-size: 16px;
  }
}
.contact-us-info1 ul > li .contact-social li:hover a {
  border: 1px solid #002147;
  background: #002147;
}
.contact-us-info1 ul > li .contact-social li:hover a i {
  color: #ffffff;
}
.contact-form1 .form-group {
  margin-bottom: 25px;
}
.contact-form1 .form-group .form-control {
  border-radius: 0;
  background: transparent;
  box-shadow: none;
  border: 1px solid #bdbdbd;
}
.contact-form1 .form-group input {
  height: 45px;
}
.contact-form1 .form-response .alert-info {
  background: transparent;
  border: none;
  color: #a8a7a7;
  letter-spacing: 5px;
}
.contact-form1 .form-response .alert-success {
  background-color: transparent;
  color: #00082e;
  letter-spacing: 4px;
  border-color: transparent;
  font-weight: 500;
}
@media only screen and (max-width: 479px) {
  .contact-form1 .form-response .alert-success {
    letter-spacing: 1px;
  }
}
.contact-form1 .form-response .alert {
  border-radius: 0;
  padding: 15px 0;
}
.google-map-area {
  margin-top: 100px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .google-map-area {
    margin-top: 90px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .google-map-area {
    margin-top: 80px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .google-map-area {
    margin-top: 70px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .google-map-area {
    margin-top: 60px;
  }
}
@media only screen and (max-width: 320px) {
  .google-map-area {
    margin-top: 50px;
  }
}
.contact-us-page2-area {
  padding: 88px 0 ;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .contact-us-page2-area {
    padding: 78px 0 ;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .contact-us-page2-area {
    padding: 68px 0 ;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .contact-us-page2-area {
    padding: 58px 0 ;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .contact-us-page2-area {
    padding: 48px 0 ;
  }
}
@media only screen and (max-width: 320px) {
  .contact-us-page2-area {
    padding: 38px 0 ;
  }
}
@media only screen and (max-width: 767px) {
  .contact-us-info2 {
    margin-bottom: 30px;
  }
}
.contact-us-info2 ul {
  margin-top: 10px;
}
.contact-us-info2 ul li {
  position: relative;
  padding: 25px 0 25px 70px;
  border: 1px solid #e6e6e6;
  font-size: 18px;
  color: #002147;
  margin-bottom: 30px;
}
.contact-us-info2 ul li:last-child {
  margin-bottom: 0;
}
@media only screen and (max-width: 1199px) {
  .contact-us-info2 ul li {
    margin-bottom: 17px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .contact-us-info2 ul li {
    padding: 25px 0 25px 40px;
  }
}
.contact-us-info2 ul li i {
  position: absolute;
  left: 23px;
  top: 22px;
  z-index: 1;
  color: #fdc800;
  font-size: 30px;
}
@media (min-width: 768px) and (max-width: 991px) {
  .contact-us-info2 ul li i {
    left: 6px;
    top: 24px;
  }
}
.contact-form2 .form-group {
  margin-bottom: 25px;
}
.contact-form2 .form-group .form-control {
  border-radius: 0;
  background: #f1f1f1;
  box-shadow: none;
  border: none;
}
.contact-form2 .form-group input {
  height: 45px;
  color: #002147;
}
.contact-form2 .form-group textarea {
  height: 178px;
}
.contact-form2 .form-response .alert-info {
  background: transparent;
  border: none;
  color: #a8a7a7;
  letter-spacing: 5px;
}
.contact-form2 .form-response .alert-success {
  background-color: transparent;
  color: #00082e;
  letter-spacing: 4px;
  border-color: transparent;
  font-weight: 500;
}
@media only screen and (max-width: 479px) {
  .contact-form2 .form-response .alert-success {
    letter-spacing: 1px;
  }
}
.contact-form2 .form-response .alert {
  border-radius: 0;
  padding: 15px 0;
}
/*=======================================================================
[27] Footer Area
=========================================================================*/
.footer-area-top {
  padding: 92px 0 50px;
  background: #002147;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .footer-area-top {
    padding: 82px 0 40px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .footer-area-top {
    padding: 72px 0 40px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .footer-area-top {
    padding: 62px 0 35px;
  }
}
@media (min-width: 321px) and (max-width: 479px) {
  .footer-area-top {
    padding: 52px 0 20px;
  }
}
@media only screen and (max-width: 320px) {
  .footer-area-top {
    padding: 42px 0 10px;
  }
}
.footer-area-top .footer-box {
  margin-bottom: 30px;
}
.footer-area-top p {
  color: #f5f5f5;
}
.footer-area-top h3 {
  font-size: 20px;
  margin-bottom: 30px;
  color: #ffffff;
}
.footer-area-top .footer-about {
  margin: 20px 0 35px;
}
.footer-area-top .footer-about p {
  font-size: 16px;
}
.footer-area-top .featured-links {
  margin-bottom: 50px;
}
.footer-area-top .featured-links > li {
  display: inline-block;
}
.footer-area-top .featured-links > li:first-child {
  margin-right: 30px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .footer-area-top .featured-links > li:first-child {
    margin-right: 10px;
  }
}
.footer-area-top .featured-links > li ul li {
  margin-bottom: 12px;
  position: relative;
  padding-left: 15px;
  font-size: 16px;
}
.footer-area-top .featured-links > li ul li:before {
  content: "\f105";
  position: absolute;
  left: 0;
  font-family: FontAwesome;
  color: #fdc800;
  top: 0;
}
.footer-area-top .featured-links > li ul li a {
  color: #f5f5f5;
  transition: all 0.5s ease-out;
}
.footer-area-top .featured-links > li ul li a:hover {
  color: #fdc800;
  transition: all 0.5s ease-out;
}
.footer-area-top .corporate-address {
  margin-bottom: 20px;
}
.footer-area-top .corporate-address li {
  margin-bottom: 10px;
  color: #f5f5f5;
  position: relative;
  padding-left: 30px;
  font-size: 16px;
}
.footer-area-top .corporate-address li i {
  color: #fdc800;
  padding-right: 15px;
  position: absolute;
  left: 0;
  top: 3px;
  z-index: 1;
  font-size: 14px;
}
.footer-area-top .corporate-address li a {
  color: #f5f5f5;
}
.footer-area-top .corporate-address li:last-child {
  margin-bottom: 0;
}
.footer-area-top .newsletter-area .stylish-input-group {
  border: none;
  padding: 0;
}
.footer-area-top .newsletter-area .stylish-input-group .form-control {
  border: none;
  box-shadow: 0 0 0;
  border-radius: 0;
  text-transform: lowercase;
  color: #717a7f;
  height: 42px;
  font-size: 13px;
  background-color: rgba(0, 8, 46, 0.3);
}
.footer-area-top .newsletter-area .stylish-input-group .input-group-addon {
  background: transparent;
  border: none;
  border-radius: 0;
  padding: 0;
  transition: all 0.3s ease-out;
}
.footer-area-top .newsletter-area .stylish-input-group .input-group-addon button {
  border: 0;
  padding: 13px 17px 14px 17px;
  background-color: rgba(0, 8, 46, 0.3);
  transition: all 0.3s ease-out;
}
.footer-area-top .newsletter-area .stylish-input-group .input-group-addon button i {
  color: #fdc800;
  transition: all 0.3s ease-out;
}
.footer-area-top .newsletter-area .stylish-input-group .input-group-addon button:hover {
  background: #fdc800;
}
.footer-area-top .newsletter-area .stylish-input-group .input-group-addon button:hover i {
  color: #002147;
}
.footer-area-top .footer-social li {
  display: inline-block;
  margin-right: 5px;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .footer-area-top .footer-social li {
    margin-right: 2px;
  }
}
.footer-area-top .footer-social li a {
  width: 30px;
  height: 30px;
  border: 1px solid #2b5788;
  display: block;
  text-align: center;
  line-height: 27px;
  transition: all 0.3s ease-out;
}
.footer-area-top .footer-social li a i {
  color: #b3b3b3;
  font-size: 13px;
  transition: all 0.3s ease-out;
}
.footer-area-top .footer-social li a:hover {
  border: 1px solid #fdc800;
  background: #fdc800;
}
.footer-area-top .footer-social li a:hover i {
  color: #002147;
}
.footer-area-top .footer-social li:last-child {
  margin-right: 0;
}
.footer-area-top .flickr-photos li {
  display: inline-block;
  width: 32%;
  margin-bottom: -2px;
}
.footer-area-top .flickr-photos li img:hover {
  opacity: 0.5;
  transition: all 0.3s ease-out;
}
.footer-area-bottom {
  background: #001a39;
  padding: 20px 0 0;
  text-transform: capitalize;
  font-size: 14px;
}
@media only screen and (max-width: 767px) {
  .footer-area-bottom {
    text-align: center;
    padding: 17px 0;
  }
}
.footer-area-bottom p {
  color: #999999;
}
@media only screen and (max-width: 767px) {
  .footer-area-bottom p {
    margin-bottom: 10px;
  }
}
.footer-area-bottom p a {
  color: #ffffff;
  transition: all 0.3s ease-out;
}
.footer-area-bottom p a:hover {
  color: #fdc800;
}
.footer-area-bottom .payment-method {
  text-align: right;
}
@media only screen and (max-width: 767px) {
  .footer-area-bottom .payment-method {
    text-align: center;
  }
}
.footer-area-bottom .payment-method li {
  display: inline-block;
}

